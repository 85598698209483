import * as AsyncM from 'control.async'
import * as Task from 'data.task'

const Async = AsyncM(Task)

export const parallel = Async.parallel
export const parallelN: <T>(tasks: Array<Task<T>>) => Task<T[]> = Async.parallel
export const liftNode = Async.liftNode
export const fromPromise = Async.fromPromise
export const toPromise = <T>(task: Task<T>) => Async.toPromise(Promise, task)
export const catchAllPossibleErrors = Async.catchAllPossibleErrors
