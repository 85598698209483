import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'

interface Props {
  username: string
}

export default class DuplicateLoginDialog extends React.Component<Props> {
  reload() {
    window.location.href = '/'
  }

  render() {
    return (
      <Dialog
        title={t('multilogin.title')}
        showPrimaryButton={true}
        showCancelButton={false}
        onPrimary={this.reload}
        disableClosing={true}
      >
        {t('multilogin.message', { username: this.props.username })}
      </Dialog>
    )
  }
}
