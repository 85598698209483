import { ClientProfile } from '../../types/types'

export const trackPage = (page: string, metadata: any) => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return
  }
  window.dataLayer.push({
    event: 'custom.page',
    customPage: {
      path: page || document.location!.pathname,
      metadata
    }
  })
}

export type AnalyticsAssessmentEventCategory =
  | 'Assess - Equipment'
  | 'Assess - Contacts'
  | 'Assess - Communication'
  | 'Assess - Buildings'
  | 'Assess - Change method'

export type AnalyticsEventCategory =
  | 'OfferingUpdate'
  | 'Salesforce refresh'
  | 'Auth time'
  | 'HTTP request'
  | AnalyticsAssessmentEventCategory

export type AnalyticsAssessmentEventAction =
  | 'Add new equipment'
  | 'Delete equipment'
  | 'Duplicate equipment'
  | 'Add contact'
  | 'Remove contact'
  | 'Change communication scope'
  | 'Duplicate building'
  | 'Delete building'
  | 'Add new building'
  | 'Change assessment method'

export type AnalyticsEventAction =
  | 'QuestionAnswer'
  | 'InteractionQuestionAnswer'
  | 'ScopeChange'
  | 'InteractionScopeChange'
  | 'QuicktenderScopeChange'
  | 'refresh opportunity'
  | 'refresh all opportunities'
  | AnalyticsAssessmentEventAction

export const trackEvent = (cat: AnalyticsEventCategory, act: AnalyticsEventAction, lab: string | undefined) => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return
  }
  window.dataLayer.push({
    event: 'custom.event',
    customEvent: {
      cat,
      act,
      lab,
      val: undefined,
      ni: false,
      metadata: undefined
    }
  })
}

export const trackTiming = (cat: AnalyticsEventCategory, variable: string | undefined, val: number) => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return
  }
  window.dataLayer.push({
    event: 'custom.timing',
    customTiming: {
      cat,
      var: variable,
      lab: null,
      val
    }
  })
}

export const setUserData = (profile: ClientProfile) => {
  if (typeof window === 'undefined' || !window.dataLayer) {
    return
  }
  const userId = profile.salesOrganization === 'KAI' ? profile.userId : profile.userIdHash
  window.dataLayer.push({
    event: 'custom.user',
    customUser: {
      id: userId,
      salesOrganization: profile.salesOrganization,
      role: profile.role,
      environment: window.environment
    }
  })
}
