import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'

export default class OfferLockedFinalizationInProgressDialog extends React.Component<Record<string, never>> {
  render() {
    return (
      <Dialog title={t('errors.errorTitle')} showPrimaryButton={true}>
        {t('offerWizard.summary.lockedSyncing')}
      </Dialog>
    )
  }
}
