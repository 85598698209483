import { isRunningTests } from '../../../common/test-utils'
type Fn1<T, R> = (value: T) => R
type Callback<T> = Fn1<T, void>
export type Unsubscriber = () => void
type CallbackArray<T> = Array<Callback<T>>

const __DEBUG__ = process.env.NODE_ENV !== 'production'

function notifySubscribers<T>(subscribers: CallbackArray<T>, value: T) {
  for (const subscriber of subscribers) {
    subscriber(value)
  }
}

function debugPrint<T>(name: string, value: T) {
  if (__DEBUG__ && !isRunningTests) {
    console.groupCollapsed(`${name} state change`)
    console.info('Value:', value)
    console.groupEnd()
  }
}

function isDefined<T>(value: T | undefined): value is T {
  return typeof value !== 'undefined'
}

function guard<T, U>(fn: Fn1<T, U>): Fn1<T | undefined, U | undefined> {
  return (value: T | undefined) => (isDefined(value) ? fn(value) : undefined)
}

export abstract class View<T> {
  static combine2<A, B, C>(atom1: View<A>, atom2: View<B>, f: (a: A, b: B) => C): View<C> {
    let aValue: A | undefined = atom1.get()
    let bValue: B | undefined = atom2.get()
    const derived = new Atom<C>('combine2')

    const computeDerived = () => {
      if (aValue !== undefined && bValue !== undefined) {
        derived.set(f(aValue, bValue))
      }
    }

    computeDerived()

    atom1.subscribe(a => {
      aValue = a
      computeDerived()
    })
    atom2.subscribe(b => {
      bValue = b
      computeDerived()
    })

    return derived
  }

  protected name: string
  protected value: T | undefined
  protected subscribers: CallbackArray<T>

  constructor(name: string, value?: T) {
    this.name = name
    this.value = value
    this.subscribers = []
  }

  /**
   * Get the current value of the view.
   *
   * Note that for convenience's sake, the type signature lies a little. If the underlying Atom hasn't been
   * initialized yet, this function will return `undefined`.
   */
  get(): T {
    return this.value as T
  }

  /** Create a derived view. */
  view<U>(name: string, fn: Fn1<T, U>, hot: boolean = false): View<U> {
    return hot ? new HotView(name, this, fn) : new ColdView(name, this, fn)
  }

  /** Subscribe to changes in the value of the view. */
  subscribe(callback: Callback<T>): Unsubscriber {
    this.subscribers.push(callback)

    return () => {
      const idx = this.subscribers.indexOf(callback)
      this.subscribers.splice(idx, 1)
    }
  }

  protected __set(value: T | undefined) {
    if (value !== this.value) {
      this.value = value
      debugPrint(this.name, this.value)
      notifySubscribers(this.subscribers, this.value)
    }
  }
}

/** A cold view subscribes to its parent view only when it has subscribers of its own. */
class ColdView<T, U> extends View<U> {
  private parent: View<T>
  private parentUnsubscriber?: Unsubscriber
  private fn: Fn1<T | undefined, U | undefined>

  constructor(name: string, parent: View<T>, fn: Fn1<T, U>) {
    super(name)
    this.parent = parent
    this.fn = guard(fn)
  }

  get(): U {
    // If we have no subscribers, the value might be out of date. In this case, we need to recompute it.
    if (this.subscribers.length === 0 || typeof this.value === 'undefined') {
      this.value = this.fn(this.parent.get())
    }

    return this.value as U
  }

  subscribe(callback: Callback<U>): Unsubscriber {
    this.subscribers.push(callback)
    this.subscribeToParentIfFirstSubscriber()

    return () => {
      const idx = this.subscribers.indexOf(callback)
      this.subscribers.splice(idx, 1)
      this.unsubscribeFromParentIfNoSubscribers()
    }
  }

  private subscribeToParentIfFirstSubscriber() {
    if (!this.parentUnsubscriber) {
      this.parentUnsubscriber = this.parent.subscribe(parentValue => {
        this.__set(this.fn(parentValue))
      })
    }
  }

  private unsubscribeFromParentIfNoSubscribers() {
    if (this.subscribers.length === 0 && this.parentUnsubscriber) {
      this.parentUnsubscriber()
      this.parentUnsubscriber = undefined
    }
  }
}

/** A hot view is always subscribed to the changes of its parent view. */
class HotView<T, U> extends View<U> {
  constructor(name: string, parent: View<T>, fn: Fn1<T, U>) {
    const guardedFn = guard(fn)
    super(name, guardedFn(parent.get()))

    parent.subscribe(parentValue => {
      this.__set(guardedFn(parentValue))
    })
  }
}

export default class Atom<T> extends View<T> {
  constructor(name: string, value?: T) {
    super(name, value)
  }

  /** Set the value of the atom. */
  set(value: T): void {
    this.__set(value)
  }

  /** Update the value of the atom by applying a function to the current value. */
  update(fn: Fn1<T, T>) {
    this.__set(fn(this.value as T))
  }
}
