import { HttpClientErrorHolder, getUITabId } from './ajax/ui'
import {
  AjaxError,
  HttpClientError,
  SalesforceError,
  SalesforcePermissionError,
  CacheExpireError
} from './ajax/http-client'
import * as Bugsnag from './bugsnag'
import * as dialog from './nemo/dialog'
import * as notification from '../../common/notification'
import { FromWindow } from './worker-mediator'
import Dexie from 'dexie'
import { t } from 'i18next'

type DialogFn = (n: notification.Notification, suppressDialog?: boolean) => void

const isDexieQuotaExceededErrorError = (e: any) =>
  e.metaData &&
  e.metaData.cause &&
  e.metaData.cause instanceof Dexie.AbortError &&
  e.metaData.cause.message === 'QuotaExceededError'

export function handleError(e: Error, dialogFn: DialogFn = defaultDialogFn, suppressDialog?: Record<string, boolean>) {
  if (e instanceof HttpClientErrorHolder) {
    handleHttpClientError(e.err, dialogFn, e.data, suppressDialog)
  } else if (isDexieQuotaExceededErrorError(e)) {
    handleDexieQuotaExceededError(e, dialogFn, !!suppressDialog && !!suppressDialog.default)
  } else {
    handleErrorNotification(notification.fromError(e), dialogFn, !!suppressDialog && !!suppressDialog.default)
  }
}
const getErrorMessage = (e: AjaxError | SalesforceError | SalesforcePermissionError | CacheExpireError): string =>
  e.data ? e.data.message || e.message : e.message
export const getErrorName = (e: AjaxError | SalesforceError | SalesforcePermissionError | CacheExpireError): string =>
  e.data ? e.data.name || e.name : e.name

export function handleErrorNotification(
  n: notification.Notification,
  dialogFn: DialogFn = defaultDialogFn,
  shouldSuppressDialog?: boolean
) {
  notifyBugsnag(n)
  dialogFn(n, shouldSuppressDialog)
}

function handleDexieQuotaExceededError(e: Error, dialogFn: DialogFn, shouldSuppressDialog: boolean = false) {
  const n = notification.fromError(e)
  notifyBugsnag(n)
  dialogFn(
    {
      name: t('errors.lowMemoryTitle'),
      message: t('errors.lowMemoryMessage')
    },
    shouldSuppressDialog
  )
}

function getNotificationFromError(
  e: AjaxError | SalesforceError | SalesforcePermissionError | CacheExpireError,
  data?: any
): notification.Notification {
  return {
    name: getErrorMessage(e),
    message: getErrorName(e),
    metaData: {
      requestId: e.requestId,
      statusCode: e.status,
      statusText: e.statusText,
      data
    }
  }
}

function handleHttpClientError(
  e: HttpClientError,
  dialogFn: DialogFn,
  data?: any,
  suppressDialog?: Record<string, boolean>
) {
  const shouldSuppressDialog = !!suppressDialog && !!suppressDialog[e._tag]

  switch (e._tag) {
    case 'NetworkError':
      return dialogFn(e, shouldSuppressDialog)
    case 'TimeoutError':
      notifyBugsnag(e)
      return dialogFn(e, shouldSuppressDialog)
    case 'ClockError':
      return dialogFn(e, shouldSuppressDialog)
    case 'UnauthorizedError':
      return FromWindow.login()
    case 'VersionMismatchError':
      return FromWindow.handleVersionMismatch()
    case 'SalesforcePermissionError':
      const notific = getNotificationFromError(e, data)
      return notifyBugsnag(notific)
    case 'CacheExpireError':
      const cacheNotific = getNotificationFromError(e, data)
      return notifyBugsnag(cacheNotific)
    default:
      const n = getNotificationFromError(e, data)
      handleErrorNotification(n, dialogFn, !!suppressDialog && !!suppressDialog.default)
  }
}

function notifyBugsnag(n: notification.Notification) {
  Bugsnag.notify({ name: n.name, message: n.message }, { metaData: n.metaData })
}

function defaultDialogFn(n: notification.Notification, shouldSuppressDialog: boolean = false) {
  dialog.hide()
  if (!shouldSuppressDialog) {
    dialog.error(n)
  }
}

export function isMessageForCurrentTab(tabId: string) {
  return tabId === '' || tabId === getUITabId()
}
