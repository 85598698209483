import * as dialog from '../nemo/dialog'
import { sharedWorkerEnabled } from '../window-utils'
import { FromServiceWorker } from '../worker-mediator'
import * as Protocol from './protocol'
import * as notification from '../../../common/notification'
import * as Bowser from 'bowser'

const START_TIME = Date.now()

export const init = (): Promise<ServiceWorkerRegistration> => {
  logCurrentStorageLimit(navigator)
  navigator.serviceWorker.addEventListener('message', serviceWorkerOnMessage)

  const url = `${location.origin}/service-worker.js?sharedworker=${sharedWorkerEnabled()}`
  return navigator.serviceWorker
    .register(url)
    .then(() => navigator.serviceWorker.ready)
    .catch(err => {
      const n: notification.Notification = {
        name: 'service-worker-client-error',
        message: 'ServiceWorker registration failed',
        metaData: {
          cause: err
        }
      }
      return Promise.reject(n)
    })
}

const controller = (): Promise<ServiceWorker> => {
  if (!navigator.serviceWorker && Bowser.firefox) {
    dialog.error({
      name: 'ServiceWorker registration failed',
      message: 'If you are using browser in private browsing mode, swith to regular mode.',
      metaData: {}
    })
  }
  const activeCtrl = navigator.serviceWorker.controller

  if (activeCtrl) {
    return Promise.resolve(activeCtrl)
  }

  logCurrentStorageLimit(navigator)

  const n: notification.Notification = {
    name: 'service-worker-client-error',
    message: 'No active controller'
  }
  return Promise.reject(n)
}

const postMessage = (message: Protocol.ClientMessage) =>
  controller()
    .then(ctrl => ctrl.postMessage(message))
    .catch(err => {
      const n: notification.Notification = {
        name: 'service-worker-client-error',
        message: `Could not send ${message.type} to ServiceWorker`,
        metaData: {
          clientMessage: message,
          cause: err
        }
      }
      return Promise.reject(n)
    })

export const logout = () =>
  postMessage({ type: 'logout' }).catch(() =>
    console.info(
      'Ignoring ServiceWorker logout rejection, this is ok,',
      'typically this occurs when the page is not yet under ServiceWorker control'
    )
  )

export const unregister = () =>
  navigator.serviceWorker
    .getRegistrations()
    .then((regs: ServiceWorkerRegistration[]) => Promise.all(regs.map(r => r.unregister())))
    .then((success: any) => console.log('ServiceWorker unregistered', success))
    .catch((error: Error) => {
      dialog.error({
        name: error.name,
        message: `ServiceWorker unregistration failed: ${error.message}`,
        metaData: {
          cause: error
        }
      })
    })

function serviceWorkerOnMessage({ data }: { data: Protocol.ServiceWorkerMessage }) {
  console.info('serviceWorkerOnMessage:', data)
  logCurrentStorageLimit(navigator)
  switch (data.type) {
    case 'login':
      return FromServiceWorker.login()
    case 'reload':
      return FromServiceWorker.handleVersionMismatch()
    case 'unhandledError':
      return dialog.error(data.message)
    default:
      return exhaustivityCheck(data)
  }

  function exhaustivityCheck(msg: never): never
  function exhaustivityCheck(msg: Protocol.ServiceWorkerMessage) {
    throw new Error(`Unhandled message ${msg}`)
  }
}

const getApplicationRunTime = () => Math.round((Date.now() - START_TIME) / 1000)

const hasStorageInformation = (n: any) => 'storage' in n && 'estimate' in n.storage

const calculatePercent = (a: number, b: number) => Math.round((b / a) * 100)

const logCurrentStorageLimit = (n: any) => {
  if (hasStorageInformation(n)) {
    n.storage.estimate().then(({ usage, quota }: any) => {
      const percent = calculatePercent(quota, usage)
      console.info(
        `[ServiceWorker] Using ${usage} out of ${quota} bytes (${percent}%) when ${getApplicationRunTime()} seconds has elapsed.`
      )
    })
  }
}
