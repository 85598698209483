import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'
import Circles from '../Circles'
import { Notification } from '../../../../../common/notification'
import * as moment from 'moment'
interface Props {
  message: Notification
  onPrimaryButton?: () => void
}

interface State {
  showExtraInformation: boolean
}

export default class ErrorDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.toggleShowExtraInformation = this.toggleShowExtraInformation.bind(this)
    this.state = {
      showExtraInformation: false
    }
  }

  toggleShowExtraInformation(event: React.SyntheticEvent<any>) {
    event.preventDefault()
    this.setState(prevState => ({ showExtraInformation: !prevState.showExtraInformation }))
  }

  render() {
    const { message, onPrimaryButton } = this.props
    const { showExtraInformation } = this.state

    // In some cases we might not have translations if i18n is not yet
    // initialized. This might occur in very early stages of application
    // startup
    const errorTitle = t('errors.errorTitle') || 'Error notification'
    return (
      <Dialog title={errorTitle} showPrimaryButton={true} onPrimary={onPrimaryButton}>
        {message.name && <p>{message.name}</p>}
        {message.message && <p>{message.message}</p>}
        <p>{moment().format()}</p>
        {message.metaData && (
          <div>
            <Circles onClick={this.toggleShowExtraInformation} />
            {showExtraInformation && (
              <div>
                <textarea className="textarea" value={JSON.stringify(message.metaData, null, 2)} readOnly={true} />
              </div>
            )}
          </div>
        )}
      </Dialog>
    )
  }
}
