import * as R from 'ramda'
import {
  Selections,
  FlexibleScope,
  EquipmentGroupStId,
  Offer,
  OfferingQuestion,
  OfferingInteractionQuestion,
  QuickTenderScope
} from '../../../../types/types'
import {
  QuickTenderCriticality,
  QuickTender,
  QuickTenderWithRatings,
  Offering,
  CriticalityPath,
  QuickTenderGroup
} from './types'
import { selections, getInitialAnswersForAllModules, getInitialAnswersForInteraction } from './selections'
import { none, some } from 'fp-ts/lib/Option'
import { Optional, Lens } from 'monocle-ts'
import { ValidForFlexible, getRatings } from '../assessment-result/types'

function getSelectionsByGroup(
  path: CriticalityPath,
  flexibleRating: FlexibleScope | null,
  offering: Offering
): QuickTenderCriticality | null {
  return flexibleRating !== null
    ? selections(path, offering)
        .map(
          (xs: Selections) =>
            ({
              flexibleRating,
              selections: xs
            } as QuickTenderCriticality | null)
        )
        .getOrElse(() => null)
    : null
}

const groupL = (groupId: EquipmentGroupStId) =>
  new Optional<QuickTender, QuickTenderGroup>(
    s => (s.groups[groupId] ? some(s.groups[groupId]) : none),
    a => s => new QuickTender(R.assoc(groupId, a, s.groups), s.scope, s.interactionSelections)
  )

const selectionsL = Lens.fromProp<QuickTenderGroup, 'selections'>('selections')

export function updateSelections(
  f: (xs: Selections) => Selections,
  groupId: EquipmentGroupStId,
  offering: QuickTender
): QuickTender {
  return groupL(groupId).compose(selectionsL.asOptional()).modify(f)(offering)
}

export function changeScope(
  groupQuestions: OfferingQuestion[],
  interactionQuestions: OfferingInteractionQuestion[],
  offering: QuickTender,
  scope: QuickTenderScope,
  offer: Offer,
  isDXEnabled: boolean
): QuickTender {
  const groups = R.map(
    g => ({
      salesToolId: g.salesToolId,
      name: g.name,
      groupKind: g.groupKind,
      selections: getInitialAnswersForAllModules(groupQuestions, scope, offer, g.salesToolId, null, isDXEnabled)
    }),
    offering.groups
  )

  return new QuickTender(groups, scope, getInitialAnswersForInteraction(interactionQuestions, scope, offer))
}

export function upgradeToQuickTenderWithRatings(
  offering: QuickTender,
  assessmentResult: ValidForFlexible
): QuickTenderWithRatings {
  return new QuickTenderWithRatings(
    R.mapObjIndexed((value, key) => {
      const elevatorRatings = getRatings(value.criticalities.elevator)
      return {
        name: value.name,
        salesToolId: key,
        groupKind: 'elevators_and_escalators',
        selections: {
          normal: getSelectionsByGroup(
            {
              groupId: key,
              equipmentCategory: 'elevator',
              criticality: 'normal'
            },
            elevatorRatings.normal,
            offering
          ),
          critical: null
        }
      }
    }, assessmentResult.groups),
    offering.scope,
    offering.interactionSelections,
    offering.scope
  )
}

export function allSelections(
  groups: Record<EquipmentGroupStId, QuickTenderGroup>
): Record<EquipmentGroupStId, Selections[]> {
  return R.map(g => [g.selections], groups)
}
