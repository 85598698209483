import { t } from 'i18next'
import * as React from 'react'

interface Props {
  onChange: (value: string) => void
  queryLength?: number
}

interface State {
  searchIsInvalid: boolean
}

export default class SearchBox extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      searchIsInvalid: false
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(event: React.FormEvent<HTMLInputElement>) {
    const query = event.currentTarget.value.trim()
    const dynamicQueryLength = this.props.queryLength ? this.props.queryLength : 3
    const searchIsInvalid = query.length > 0 && query.length < dynamicQueryLength
    if (searchIsInvalid !== this.state.searchIsInvalid) {
      this.setState({
        searchIsInvalid
      })
    }

    if (!searchIsInvalid) {
      this.props.onChange(query)
    }
  }

  render() {
    const { searchIsInvalid } = this.state
    return (
      <div className="search">
        <input
          maxLength={20}
          onChange={this.onChange}
          id="customers-search"
          className={searchIsInvalid ? 'input is-danger' : 'input is-primary'}
          placeholder={t('customers.search')}
        />
        <div className="icon icon-magnifier" />
      </div>
    )
  }
}
