import { ClientOffer, ContractFilenames, OfferPreviousVersion } from '../../../../types/types'

export const updateContractEmailTimestamp = (offerVersionId: string, contractEmailTimestamp: string) => (
  offer: ClientOffer
): ClientOffer => {
  /* Find correct previous version that was updated */
  const previousVersions = offer.previousVersions.map(p =>
    p.id === offerVersionId
      ? {
          ...p,
          contractEmailTimestamp
        }
      : p
  )

  if (offer.id === offerVersionId) {
    /* For active version we must update previous versions and active offer */
    return {
      ...offer,
      previousVersions,
      contractEmailTimestamp
    }
  } else {
    /* For non active, it's enough to just update previous version */
    return {
      ...offer,
      previousVersions
    }
  }
}

export const updateContractFilenames = (offerVersionId: string, contractDocuments: ContractFilenames) => (
  offer: ClientOffer
): ClientOffer => {
  const newGenDoc = contractDocuments.gendoc
  const newDocGen = contractDocuments.docgen
  const prevVersion = offer.previousVersions.find(o => o.id === offerVersionId)!
  const contractFilenames = {
    gendoc: {
      ...prevVersion.contractFilenames.gendoc,
      ...newGenDoc
    },
    docgen: {
      ...prevVersion.contractFilenames.docgen,
      ...newDocGen
    }
  }

  /* Find correct previous version that was updated */
  const previousVersions = offer.previousVersions.map(p =>
    p.id === offerVersionId
      ? {
          ...p,
          contractFilenames
        }
      : p
  )

  if (offer.id === offerVersionId) {
    /* For active version we must update previous versions and active offer */
    return {
      ...offer,
      previousVersions,
      contractFilenames
    }
  } else {
    /* For non active, it's enough to just update previous version */
    return {
      ...offer,
      previousVersions
    }
  }
}

export const updateRecreatedDocuments = (
  enablerFilename: string,
  contractFilenames: ContractFilenames,
  previousVersions: OfferPreviousVersion[]
) => (offer: ClientOffer): ClientOffer => ({
  ...offer,
  enablerFilename,
  contractFilenames,
  previousVersions
})
