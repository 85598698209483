import * as React from 'react'
import * as ReactDom from 'react-dom'
import ErrorDialog from '../components/common/Dialog/ErrorDialog'

const $modalDialog = document.querySelector('#modal-dialog-container') as Element

export function hide() {
  ReactDom.unmountComponentAtNode($modalDialog)
}

export function show(dialog: JSX.Element) {
  hide()
  ReactDom.render(dialog, $modalDialog)
}

export function render(dialog: JSX.Element) {
  ReactDom.render(dialog, $modalDialog)
}

export function error(message: any, onPrimaryButton?: () => void) {
  hide()
  const d = <ErrorDialog message={message} onPrimaryButton={onPrimaryButton} />
  ReactDom.render(d, $modalDialog)
}
