import { version } from '../../../common/version.json'

export const LOGGER = `[SW ${version}]`

export function info(...args: any[]) {
  console.info(LOGGER, ...args)
}

export function warn(...args: any[]) {
  console.warn(LOGGER, ...args)
}

export function error(...args: any[]) {
  console.error(LOGGER, ...args)
}
