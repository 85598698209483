import * as ClassNames from 'classnames'
import * as React from 'react'
import { MouseEventHandler } from 'react'

const spinner = <span className="icon icon-gear spinning" />

interface Props {
  className?: string
  id?: string
  isDisabled?: boolean
  isLoading?: boolean
  isOnline?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export default class Button extends React.PureComponent<Props, Record<string, never>> {
  render() {
    const { children, isLoading, isDisabled, id, isOnline, onClick, className } = this.props
    const classNames = ClassNames(
      'button',
      {
        'button-spinner': isLoading,
        'offline-mode': isOnline === false
      },
      className
    )

    return (
      <button onClick={onClick} id={id} className={classNames} disabled={isLoading || isDisabled || isOnline === false}>
        {isLoading ? spinner : null}
        {children}
      </button>
    )
  }
}
