import * as R from 'ramda'
import {
  EquipmentGroupStId,
  Criticality,
  Selections,
  QuickTenderScope,
  Offer,
  OfferingQuestion,
  OfferingInteractionQuestion
} from '../../../../types/types'
import {
  QuickTenderGroupWithRating,
  QuickTenderWithRatings,
  QuickTenderEquipmentCategory,
  CriticalityPath
} from './types'
import { some, none, Option } from 'fp-ts/lib/Option'
import { Optional, Lens } from 'monocle-ts'
import { getInitialAnswersForInteraction, getInitialAnswersForAllModules } from './selections'

const groupL = (groupId: EquipmentGroupStId) =>
  new Optional<QuickTenderWithRatings, QuickTenderGroupWithRating>(
    s => (s.groups[groupId] ? some(s.groups[groupId]) : none),
    a => s =>
      new QuickTenderWithRatings(
        R.assoc(groupId, a, s.groups),
        s.scope,
        s.interactionSelections,
        s.selectedInteractionRating
      )
  )

const categoryL = Lens.fromProp<QuickTenderGroupWithRating, 'selections'>('selections')

const selectionsL = (criticality: Criticality) =>
  new Optional<QuickTenderEquipmentCategory, Selections>(
    s =>
      criticality === 'normal'
        ? s.normal
          ? some(s.normal.selections)
          : none
        : s.critical
        ? some(s.critical.selections)
        : none,
    sel => s =>
      criticality === 'normal' && s.normal
        ? { ...s, normal: { flexibleRating: s.normal.flexibleRating, selections: sel } }
        : s.critical
        ? {
            ...s,
            critical: { flexibleRating: s.critical.flexibleRating, selections: sel }
          }
        : s
  )

export function updateQuickTenderGroupSelections(f: (xs: Selections) => Selections, criticality: Criticality) {
  return categoryL.composeOptional(selectionsL(criticality)).modify(f)
}

export function updateSelections(
  f: (xs: Selections) => Selections,
  path: CriticalityPath,
  offering: QuickTenderWithRatings
): QuickTenderWithRatings {
  return groupL(path.groupId).composeLens(categoryL).compose(selectionsL(path.criticality)).modify(f)(offering)
}

export function selections(path: CriticalityPath, offering: QuickTenderWithRatings): Option<Selections> {
  return groupL(path.groupId).composeLens(categoryL).compose(selectionsL(path.criticality)).getOption(offering)
}

export function allSelections(
  groups: Record<EquipmentGroupStId, QuickTenderGroupWithRating>
): Record<EquipmentGroupStId, Selections[]> {
  return R.map(g => [g.selections.normal ? g.selections.normal.selections : {}], groups)
}

export function changeScope(
  groupQuestions: OfferingQuestion[],
  interactionQuestions: OfferingInteractionQuestion[],
  offering: QuickTenderWithRatings,
  scope: QuickTenderScope,
  offer: Offer,
  isDXEnabled: boolean
): QuickTenderWithRatings {
  function buildCategory(
    category: QuickTenderEquipmentCategory,
    groupId: EquipmentGroupStId
  ): QuickTenderEquipmentCategory {
    return {
      normal: category.normal
        ? {
            flexibleRating: category.normal.flexibleRating,
            selections: getInitialAnswersForAllModules(groupQuestions, scope, offer, groupId, 'normal', isDXEnabled)
          }
        : null,
      critical: null
    }
  }

  const groups = R.map(
    g => ({
      salesToolId: g.salesToolId,
      name: g.name,
      groupKind: g.groupKind,
      selections: buildCategory(g.selections, g.salesToolId)
    }),
    offering.groups
  )

  return new QuickTenderWithRatings(
    groups,
    scope,
    getInitialAnswersForInteraction(interactionQuestions, scope, offer),
    offering.selectedInteractionRating
  )
}
