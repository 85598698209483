export function isRunningTests() {
  // Check if running Mocha. Mocha adds certain functions to global.
  // Another approach would be to check if mocha is present in process.argv
  return ['afterEach', 'after', 'beforeEach', 'before', 'describe', 'it'].every(
    name => (global as any)[name] instanceof Function
  )
}

export function supressLogs(mochaB: any, mochaA: any) {
  const originalError = console.error.bind(console.error)
  const originalWarn = console.warn.bind(console.warn)
  const originalLog = console.log.bind(console.log)

  mochaB(function () {
    console.error = (msg: any) => !msg.toString() && originalError(msg)
    console.warn = (msg: any) => !msg.toString() && originalWarn(msg)
    console.log = (msg: any) => !msg.toString() && originalLog(msg)
  })
  mochaA(function () {
    console.error = originalError
    console.warn = originalWarn
    console.log = originalLog
  })
}
