import * as Task from 'data.task'
import * as logger from '../../js/service-worker/logger'
import { AjaxRequestParameters, HttpClientError, makeRequest } from './http-client'
import {
  CheckAuthResponse,
  SendEnablerRequest,
  GetContractAttachmentResponse,
  GetContractPdfResponse,
  UpdateDiscountApprovalStatusResponse,
  UserSettings,
  GetContractPdfRequest,
  SendContractPdfRequest,
  CreateGendocXmlRequest,
  RecreateDocumentsRequest,
  ServerTimeResponse,
  ApiMasterData
} from '../../../types/backend-api'
import {
  DocumentTemplate,
  OfferDbId,
  OpportunityCustomer,
  OpportunitySfId,
  SavedOffer,
  Offer,
  ContractFilenames,
  CompletedOffer,
  ClockDriftData,
  OpportunityShareSearchResult,
  ClientPerformanceReportResult,
  SapContractParameters,
  EscalationContractBillingPlan,
  Contact,
  Opportunity
} from '../../../types/types'
import { DocumentGenerator } from '../../../types/documentGenerator'
import { getUUID } from '../../../common/common-utils'

import { UserDetails } from '../../../backend/passport'

export function getUITabId(key = 'TAB_ID') {
  let tabId = sessionStorage.getItem(key)
  if (tabId === null) {
    tabId = getUUID()
    sessionStorage.setItem(key, tabId)
  }
  return tabId
}

export class HttpClientErrorHolder extends Error {
  constructor(readonly err: HttpClientError, readonly data?: any) {
    super()
    if (err) {
      logger.error(err)
    }
  }
}

const get = <T>(params: AjaxRequestParameters) =>
  makeRequest<T>('GET', params).rejectedMap(e => new HttpClientErrorHolder(e as HttpClientError, params.data))

const post = <T>(params: AjaxRequestParameters) =>
  makeRequest<T>('POST', params).rejectedMap(e => new HttpClientErrorHolder(e as HttpClientError, params.data))

export function getData(): Task<ApiMasterData> {
  return get({ path: 'data', contentType: 'application/json' })
}

export function pricingEnginePrediction(data: any): Task<any> {
  return post({
    contentType: 'application/json',
    data,
    path: `pricing-engine-discount`
  })
}

export function getCustomerAccounts(): Task<OpportunityCustomer[]> {
  return get({ path: 'data/customerAccounts', contentType: 'application/json' })
}

export function getCustomerContacts(): Task<{ id: string; contacts: Contact[] }[]> {
  return get({ path: 'data/customerContacts', contentType: 'application/json' })
}

export function getOpportunities(): Task<{ id: string; opportunities: Opportunity[] }[]> {
  return get({ path: 'data/customerOpportunities', contentType: 'application/json' })
}

export function getUserDetails(userId: string): Task<UserDetails> {
  return get({
    path: `user-details/${userId}`,
    contentType: 'application/json'
  })
}
export function getSavedOffersForOpportunities(opportunityIds: string[]): Task<Offer[]> {
  return post({
    path: 'data/savedOffersForOpportunities',
    data: Object.assign({}, { opportunityIds }),
    contentType: 'application/json'
  })
}

export function checkAuth(): Task<CheckAuthResponse> {
  return get({ path: 'check-authentication' })
}

export function saveOfferToBackend(offer: Offer, tabId: string = getUITabId()): Task<SavedOffer> {
  return post<CompletedOffer>({
    path: `offers/${offer.opportunityId}`,
    data: Object.assign(offer, { tabId }),
    timeout: 5 * 60 * 1000,
    contentType: 'application/json'
  }).map(result => result as SavedOffer)
}

export function saveOfferToBackendFromContract(offer: Offer, tabId: string = getUITabId()): Task<SavedOffer> {
  return post<CompletedOffer>({
    path: `offers/${offer.opportunityId}/${'true'}`,
    data: Object.assign(offer, { tabId }),
    timeout: 5 * 60 * 1000,
    contentType: 'application/json'
  }).map(result => result as SavedOffer)
}

export function createSapContract(formData: any): Task<SavedOffer> {
  return post<CompletedOffer>({
    path: 'sap',
    data: formData,
    timeout: 5 * 60 * 1000,
    contentType: '' // 'multipart/form-data' causes problems with postman
  })
}

export function getCreateContractXML(sapContractParameters: SapContractParameters): Task<SavedOffer> {
  return post({
    path: 'sap/get-sap-xml',
    data: { sapContractParameters },
    contentType: 'application/json'
  })
}

export function sendEnabler(data: SendEnablerRequest) {
  return post({
    path: 'enablers',
    data,
    contentType: 'application/json'
  })
}

export function getContractPdf(
  offerId: OfferDbId,
  language: string,
  documentGenerator: DocumentGenerator,
  documentTemplate: string,
  tabId: string = getUITabId()
) {
  return post<GetContractPdfResponse>({
    path: `documents/get-contract-pdf/${offerId}`,
    contentType: 'application/json',
    data: { language, documentGenerator, documentTemplate, tabId } as GetContractPdfRequest
  })
}

export function updateDiscountApprovalStatus(
  offerId: OfferDbId,
  tenderVersionId: string
): Task<UpdateDiscountApprovalStatusResponse> {
  return post({ path: `discount_approval/${offerId}/${tenderVersionId}` })
}

export function createGendocXml(offerId: OfferDbId, language: string, documentTemplate: string) {
  return post({
    path: `documents/xml/${offerId}`,
    data: { language, documentTemplate } as CreateGendocXmlRequest,
    contentType: 'application/json'
  })
}

export function recreateDocuments(
  offerId: OfferDbId,
  enabledDocumentTemplates: DocumentTemplate[],
  tabId: string = getUITabId()
): Task<SavedOffer> {
  return post({
    path: `documents/recreate/${offerId}`,
    data: { enabledDocumentTemplates, tabId } as RecreateDocumentsRequest,
    contentType: 'application/json'
  })
}

export function getDiscountSummaryPdf(
  offerId: OfferDbId,
  discountApprovalComment: string | null,
  tabId: string = getUITabId()
) {
  return post({
    path: `documents/get-discount-summary-pdf/${offerId}`,
    contentType: 'application/json',
    data: {
      tabId,
      discountApprovalComment
    }
  })
}

export function sendContractPdf(
  offerId: OfferDbId,
  to: string,
  ccTo: string,
  contractFilenames: ContractFilenames,
  language: string,
  documentGenerator: DocumentGenerator,
  documentTemplate: string,
  tabId: string = getUITabId()
) {
  return post({
    path: `documents/send-contract-pdf/${offerId}`,
    data: {
      to,
      ccTo,
      contractFilenames,
      language,
      documentGenerator,
      documentTemplate,
      tabId
    } as SendContractPdfRequest,
    contentType: 'application/json'
  })
}

export function finalizeOffer(id: OfferDbId, tabId: string = getUITabId()) {
  return post({
    path: `offers/${id}/finalize`,
    contentType: 'application/json',
    data: {
      tabId
    }
  })
}

export function activateOffer(id: OfferDbId, tabId: string = getUITabId()): Task<SavedOffer> {
  return post({
    path: `offers/${id}/activate`,
    contentType: 'application/json',
    data: {
      tabId
    }
  })
}

export function logout() {
  return post({ path: 'logout' })
}

export function updateUserSettings(language: string): Task<UserSettings> {
  return post({
    contentType: 'application/json',
    data: { language },
    path: 'settings'
  })
}

export function getContractAttachment(
  offerId: OfferDbId,
  attachmentNumber: number
): Task<GetContractAttachmentResponse> {
  return get({ path: `sap/attachments/${offerId}/${attachmentNumber}` })
}

export function clearSfCache() {
  return post({
    path: 'salesforce/clear_cache',
    contentType: 'application/json'
  })
}

export function getOpportunity(opportunityId: OpportunitySfId): Task<OpportunityCustomer> {
  return get({ path: `opportunity/${opportunityId}` })
}

export function getOpportunitiesWithOutConfig(data: any): Task<OpportunityCustomer> {
  return post({
    contentType: 'application/json',
    data,
    path: `opportunities-without-config`
  })
}

export function getServerTimeFor(getTime: () => number): Task<ClockDriftData> {
  return get({ path: `ping/clock/?timestamp=${getTime()}` }).chain((response: ServerTimeResponse) => {
    const timestamp = getTime()
    const latency = timestamp - response.browserRequestTime
    return Task.of(
      Object.assign({}, response, {
        responseReadyTime: timestamp,
        latency,
        browserClockDrift: timestamp - (response.serverResponseTime + latency / 2)
      })
    )
  })
}

export function getEscalationBillingPlanList(opportunityId: OpportunitySfId): Task<EscalationContractBillingPlan[]> {
  return get({ path: `escalation/${opportunityId}` })
}

export function searchOpportunities(query: string): Task<OpportunityShareSearchResult[]> {
  return get({ path: `opportunity/search?query=${query}` })
}

export function shareOpportunities(opportunityIds: OpportunitySfId[]): Task<number> {
  return post({
    contentType: 'application/json',
    data: { opportunityIds },
    path: 'opportunity/share'
  })
}

export function unShareOpportunities(opportunityIds: OpportunitySfId[]): Task<number> {
  return post({
    contentType: 'application/json',
    data: { opportunityIds },
    path: 'opportunity/unshare'
  })
}

export function loadResourceForAnalysis(uri: string): Task<string> {
  const url = location.origin + uri
  return get({
    contentType: 'application/json',
    cacheControl: 'no-cache',
    path: '',
    url
  })
}

export function saveClientPerformanceReport(result: ClientPerformanceReportResult): Task<string> {
  return post({
    contentType: 'application/json',
    data: { result },
    path: 'performance-report/save'
  })
}
