import * as moment from 'moment'
import * as R from 'ramda'
import { Offer, OfferPreviousVersion } from '../types/types'

// The offer is unsaved when one of these is true:
//
// 1. it has no server timestamp
// 2. the client timestamp is newer than the server timestamp
// 3. the offer is completed (has been confirmed by the user) but has no tender version
//
// Offer -> Boolean
export const isOfferUnsaved = (offer: Offer) =>
  // new offer, no server timestamp
  !offer.modified_server ||
  // has already been saved and has been modified locally?
  moment(offer.modified_client).isAfter(moment(offer.modified_server))

export const isOfferFinalized = (offer: Offer) => !!offer.modified_server && offer.finalized

export const isFinalizationStarted = (offer: Offer) => offer.finalization_started != null && !offer.finalized

export const offerHasActiveTender = (offer: Offer) => !!offer.tenderVersionNumber

export const offerIsCompleted = (offer: Offer) => offer.completed || offerHasActiveTender(offer)

export const offerIsSyncing = (offer: Offer) =>
  offer.lastSyncStarted != null && moment(offer.lastSyncStarted).add(60, 'seconds').isAfter(moment())

export const isOfferEmpty = (offer?: Offer) => R.isNil(offer) || R.isEmpty(offer)

export const isOfferLocked = (offer: Offer | OfferPreviousVersion): boolean => {
  if (!offer.finalization_started) {
    // Finalization has not started or it has been stopped by the server
    return false
  }
  if (offer.finalization_started === true) {
    // Boolean value means finalization is started but browser offer is not yet synced to backend
    return true
  }
  return typeof offer.finalization_started === 'string' &&
    moment(offer.finalization_started, moment.ISO_8601).add(180, 'seconds').isAfter(moment())
    ? true
    : false
}

export const isOfferApproved = (offer: Offer) => offer.discountApprovalStatus === 'approved'

export const isFinalizationIncomplete = (offer: Offer): boolean =>
  !!(
    offer.finalizationProgress &&
    Object.keys(offer.finalizationProgress).length > 0 &&
    offer.finalizationProgress.percentComplete === 'incomplete' &&
    offer.discountApprovalStatus !== 'approved'
  )
