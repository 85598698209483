import * as React from 'react'
import * as dialog from '../nemo/dialog'
import { DedicatedWorkerClient } from './dedicated-worker-client'
import { SharedWorkerClient } from './shared-worker-client'
import * as WsProtocol from './ws-worker-protocol'
import * as ui from '../ui-thread'
import { sharedWorkerEnabled } from '../window-utils'
import { checkForSAPOIInprogress } from '../utils'
import * as db from '../db/db'
import {
  updateContractFilenames,
  updateContractEmailTimestamp,
  updateRecreatedDocuments
} from '../nemo/offer/contract-documents'

import { FromWebWorkers } from '../worker-mediator'
import ActionCompletedDialog from '../components/common/Dialog/ActionCompletedDialog'
import DuplicateLoginDialog from '../components/common/Dialog/DuplicateLoginDialog'
import { Profile } from '../state/profile'
import * as notification from '../../../common/notification'

const onWsMessage = (message: WsProtocol.WorkerMessage) => {
  if (!shouldMessageBeHandled(message)) {
    console.info(`Ditch web worker message '${message._tag}' since it's not targeted for this tab or browser`)
    return
  }
  switch (message._tag) {
    case 'Login':
      return FromWebWorkers.login()
    case 'FinalizeError':
      return ui.handleErrorNotification(message.error)
    case 'OnlineState':
      return FromWebWorkers.onlineState(message.isOnline, message.isUpgradeOn)
    case 'Reload':
      return FromWebWorkers.handleVersionMismatch()
    case 'UpdatedOpportunityIds':
      return FromWebWorkers.opportunitiesChanged(message.ids)
    case 'OfferUpdated':
      if (!ui.isMessageForCurrentTab(message.tabId)) {
        return FromWebWorkers.viewOfferVersionOutdatedDialog()
      }
      return
    case 'SAPContractCreated':
      return db.updateOffer({ ...message.offer, sapOIStartTimestamp: null }, true).fork(
        (err: any) =>
          ui.handleError({
            name: 'ws-worker-error',
            message: err.message
          }),
        _ => {
          checkForSAPOIInprogress()
          FromWebWorkers.opportunitiesChanged([message.offer.opportunityId])
          return FromWebWorkers.viewOfferVersionSentToSAPDialog()
        }
      )
    case 'SAPContractCreationError':
      return db.updateOfferSAPDetails(message.opportunityId).fork(
        (err: any) =>
          ui.handleError({
            name: 'ws-worker-error',
            message: err.message
          }),
        () => {
          checkForSAPOIInprogress()
          ui.handleErrorNotification(message.error)
          return FromWebWorkers.opportunitiesChanged([message.opportunityId])
        }
      )
    case 'UpdateOfferDetails':
      return db.updateOfferDetails(message.opportunityId, message.values, true).fork(
        (err: any) =>
          ui.handleError({
            name: 'ws-worker-error',
            message: err.message
          }),
        () => FromWebWorkers.opportunitiesChanged([message.opportunityId])
      )
    case 'UpdateOfferFinalizationProgress':
      return db.updateOfferDetails(message.opportunityId, message.values).fork(
        (err: any) =>
          ui.handleError({
            name: 'ws-worker-error',
            message: err.message
          }),
        () => FromWebWorkers.opportunitiesChanged([message.opportunityId])
      )
    case 'OfferContractDocumentsUpdated':
      const updateFilenames = updateContractFilenames(message.offerId, message.updatedContractFileNames)
      return db.updateOfferWith(message.opportunityId, updateFilenames).fork(
        (err: any) =>
          ui.handleError({
            name: 'ws-worker-error',
            message: err.message
          }),
        () => FromWebWorkers.opportunitiesChanged([message.opportunityId])
      )
    case 'OfferContractDocumentsRecreated':
      const updateRecreated = updateRecreatedDocuments(
        message.enablerFilename,
        message.contractFilenames,
        message.previousVersions
      )

      return db.updateOfferWith(message.opportunityId, updateRecreated).fork(
        (err: any) =>
          ui.handleError({
            name: 'ws-worker-error',
            message: err.message
          }),
        () => FromWebWorkers.opportunitiesChanged([message.opportunityId])
      )
    case 'OfferContractDocumentsEmailSent':
      const updateFilenames2 = updateContractFilenames(message.offerId, message.updatedContractFileNames)
      const updateEmailTimestamp = updateContractEmailTimestamp(message.offerId, message.timestamp)
      return db
        .updateOfferWith(message.opportunityId, updateEmailTimestamp)
        .chain(_ => db.updateOfferWith(message.opportunityId, updateFilenames2))
        .fork(
          (err: any) =>
            ui.handleError({
              name: 'ws-worker-error',
              message: err.message
            }),
          () => FromWebWorkers.opportunitiesChanged([message.opportunityId])
        )
    case 'OpenPdfContract':
      return FromWebWorkers.openPdfContract(message.url)
    case 'ErrorPdfContract':
      return ui.handleError({
        name: 'pdf-create-error',
        message: message.error
      })
    case 'ShowActionCompletedMessage':
      const d1 = <ActionCompletedDialog translationKey={message.translationKey} />
      return dialog.show(d1)
    case 'UpgradeStarted':
      return FromWebWorkers.onlineState(false, true)
    case 'DuplicateLoginDetected':
      api.terminate()
      const un = Profile.get().displayName
      const d2 = <DuplicateLoginDialog username={un} />
      return dialog.show(d2)
    case 'WsError':
      // WsError will happen when websocket encounters an unknown error.
      // It is not easy for user to understand this message as it can be e.g.
      // "Websocket error", which tells absolutely nothing.
      // In addition, with iOS devices, locking a device screen will close our websocket.
      // Once display is unlocked it will result a WsError because of disconnected
      // websocket. This is not critical error as websocket immediately reconnects.
      // Because of these problems we are not showing error popup but let error go
      // to Bugsnag.
      const swallowNotification = (_: notification.Notification) => null

      return ui.handleError(
        {
          name: 'ws-worker-error',
          message: message.message
        },
        swallowNotification
      )
  }
}

const shouldMessageBeHandled = (message: WsProtocol.WorkerMessage) => {
  switch (message._tag) {
    case 'FinalizeError':
    case 'OpenPdfContract':
    case 'ErrorPdfContract':
    case 'ShowActionCompletedMessage':
    case 'UpdateOfferDetails':
    case 'UpdateOfferFinalizationProgress':
    case 'OfferContractDocumentsUpdated':
    case 'OfferContractDocumentsRecreated':
    case 'OfferContractDocumentsEmailSent':
    case 'WsError':
      return ui.isMessageForCurrentTab(message.tabId)
    default:
      return true
  }
}

interface WorkerClients {
  init: () => void
  terminate: () => void
}

class DedicatedWorkerClients implements WorkerClients {
  private wsWorker: DedicatedWorkerClient<never, WsProtocol.WorkerMessage>

  init = () => {
    this.wsWorker = new DedicatedWorkerClient('ws-worker-dedicated')
    this.wsWorker.start(onWsMessage)
  }

  terminate = () => {
    if (this.wsWorker) {
      this.wsWorker.terminate()
    }
  }
}

class SharedWorkerClients implements WorkerClients {
  private wsWorker: SharedWorkerClient<never, WsProtocol.WorkerMessage>

  init = () => {
    this.wsWorker = new SharedWorkerClient('ws-worker')
    this.wsWorker.start(onWsMessage)
  }

  terminate = () => {
    if (this.wsWorker) {
      this.wsWorker.terminate()
    }
  }
}

// NOTE: android and linux chrome does not support shared workers, fallback to dedicated workers
const api: WorkerClients = !sharedWorkerEnabled() ? new DedicatedWorkerClients() : new SharedWorkerClients()

export default api
