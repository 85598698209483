import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'

interface Props {
  translationKey: string
}

/* This dialog can be used in cases where you need to notify user about
 * completed action but when there are no translations available (e.g. workers ) */
export default class ActionCompletedDialog extends React.Component<Props, Record<string, never>> {
  render() {
    return (
      <Dialog title={t('dialog.completedTitle')} showPrimaryButton={true}>
        {t(this.props.translationKey)}
      </Dialog>
    )
  }
}
