// client originated messages

export class TerminateWorker {
  readonly _tag: 'TerminateWorker' = 'TerminateWorker' as const
}
export class Start {
  readonly _tag: 'Start' = 'Start' as const
}
export type ClientMessage<T> = Custom<T> | Start | TerminateWorker

// worker originated messages

export type WorkerMessage<T> = Custom<T>

// shared

export class Custom<T> {
  readonly _tag: 'Custom' = 'Custom' as const
  constructor(readonly wrapped: T) {}
}
