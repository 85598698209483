import * as R from 'ramda'
import {
  Flexible,
  CriticalityPath,
  FlexibleGroup,
  FlexibleCriticalities,
  FlexibleRatingsAndSelections,
  QuickTenderWithRatings,
  QuickTenderEquipmentCategory,
  ElevatorsEscalatorsApfs,
  Doors,
  DoorGroups,
  ElevatorEscalatorApfGroups,
  Groups
} from './types'
import { Lens, Optional } from 'monocle-ts'
import {
  EquipmentGroupStId,
  EquipmentCategory,
  Criticality,
  Selections,
  QuickTenderScope,
  Offer,
  OfferingInteractionQuestion,
  OfferingQuestion
} from '../../../../types/types'
import { some, Option, none } from 'fp-ts/lib/Option'
import { getInitialAnswersForAllModules, getInitialAnswersForInteraction } from './selections'

const groupL = (groupId: EquipmentGroupStId) =>
  new Optional<Flexible, FlexibleGroup<ElevatorsEscalatorsApfs | Doors>>(
    s => (s.groups.groups[groupId] ? some(s.groups.groups[groupId]) : none),
    a => offering =>
      new Flexible(
        // TODO how on earth can we get rid of the coersions here?
        match(offering.groups, {
          ElevatorEscalatorApfGroups: groups =>
            a.equipment instanceof ElevatorsEscalatorsApfs
              ? new ElevatorEscalatorApfGroups({ ...groups, [groupId]: a } as Groups<ElevatorsEscalatorsApfs>)
              : offering.groups,
          DoorGroups: groups =>
            a.equipment instanceof Doors
              ? new DoorGroups({ ...groups, [groupId]: a } as Groups<Doors>)
              : offering.groups
        }),
        offering.interaction
      )
  )

const categoryL = (category: EquipmentCategory) =>
  new Optional<FlexibleGroup<ElevatorsEscalatorsApfs | Doors>, FlexibleCriticalities>(
    s => {
      switch (category) {
        case 'elevator':
          return s.equipment instanceof ElevatorsEscalatorsApfs && s.equipment.elevator
            ? some(s.equipment.elevator)
            : none
        case 'escalator':
          return s.equipment instanceof ElevatorsEscalatorsApfs && s.equipment.escalator
            ? some(s.equipment.escalator)
            : none
        case 'door':
          return s.equipment instanceof Doors && s.equipment.door ? some(s.equipment.door) : none
        case 'resiflow':
          return s.equipment instanceof ElevatorsEscalatorsApfs && s.equipment.resiflow
            ? some(s.equipment.resiflow)
            : none
      }
    },
    a => s => {
      if (s.equipment instanceof ElevatorsEscalatorsApfs) {
        switch (category) {
          case 'elevator':
            return {
              ...s,
              equipment: ElevatorsEscalatorsApfs.fromObject({
                elevator: a,
                escalator: s.equipment.escalator,
                resiflow: null
              })
            }
          case 'escalator':
            return {
              ...s,
              equipment: ElevatorsEscalatorsApfs.fromObject({
                elevator: s.equipment.elevator,
                escalator: a,
                resiflow: null
              })
            }
          case 'resiflow':
            return {
              ...s,
              equipment: ElevatorsEscalatorsApfs.fromObject({
                elevator: null,
                escalator: null,
                resiflow: a
              })
            }
          default:
            return s
        }
      } else {
        return {
          ...s,
          equipment: Doors.fromObject({ door: a })
        }
      }
    }
  )

const criticalityL = (criticality: Criticality) =>
  new Optional<FlexibleCriticalities, FlexibleRatingsAndSelections>(
    s => (criticality === 'normal' ? (s.normal ? some(s.normal) : none) : none),
    a => s => R.assoc(criticality, a, s)
  )

const selectionsL = Lens.fromProp<FlexibleRatingsAndSelections, 'selections'>('selections')

export function updateFlexibleRatingsAndSelections(
  f: (x: FlexibleRatingsAndSelections) => FlexibleRatingsAndSelections,
  path: CriticalityPath,
  offering: Flexible
): Flexible {
  return groupL(path.groupId)
    .compose(categoryL(path.equipmentCategory))
    .compose(criticalityL(path.criticality))
    .modify(f)(offering)
}

export function updateSelections(
  f: (xs: Selections) => Selections,
  path: CriticalityPath,
  offering: Flexible
): Flexible {
  return groupL(path.groupId)
    .compose(categoryL(path.equipmentCategory))
    .compose(criticalityL(path.criticality))
    .compose(selectionsL.asOptional())
    .modify(f)(offering)
}

export function ratingsAndSelections(path: CriticalityPath, offering: Flexible): Option<FlexibleRatingsAndSelections> {
  return groupL(path.groupId)
    .compose(categoryL(path.equipmentCategory))
    .compose(criticalityL(path.criticality))
    .getOption(offering)
}

// Get all selections for all equipment categories and all criticalities
export function allSelections(
  groups: ElevatorEscalatorApfGroups | DoorGroups
): Record<EquipmentGroupStId, Selections[]> {
  function selectionsForCategory(category: FlexibleCriticalities): Selections[] {
    return category.normal ? [category.normal.selections] : []
  }

  return match(groups, {
    ElevatorEscalatorApfGroups: gs =>
      R.map(g => {
        const eleSelections = g.equipment.elevator ? selectionsForCategory(g.equipment.elevator) : []
        const escSelections = g.equipment.escalator ? selectionsForCategory(g.equipment.escalator) : []
        const resiflowSelections = g.equipment.resiflow ? selectionsForCategory(g.equipment.resiflow) : []
        return eleSelections.concat(escSelections).concat(resiflowSelections)
      }, gs),
    DoorGroups: gs => R.map(g => (g.equipment.door ? selectionsForCategory(g.equipment.door) : []), gs)
  })
}

export function selections(path: CriticalityPath, offering: Flexible) {
  return groupL(path.groupId)
    .compose(categoryL(path.equipmentCategory))
    .compose(criticalityL(path.criticality))
    .compose(selectionsL.asOptional())
    .getOption(offering)
}

function doesGroupsHasPeopleflowGroup(offering: Flexible): boolean {
  for (const salesToolId in offering.groups.groups) {
    const equipmentGroup = offering.groups.groups[salesToolId]
    if (equipmentGroup.groupKind === 'people_flow') {
      return true
    }
  }
  return false
}

export function switchToQuickTenderScope(
  groupQuestions: OfferingQuestion[],
  interactionQuestions: OfferingInteractionQuestion[],
  offering: Flexible,
  scope: QuickTenderScope,
  offer: Offer,
  isDXEnabled: boolean
): QuickTenderWithRatings {
  function buildCategory(category: FlexibleCriticalities, groupId: EquipmentGroupStId): QuickTenderEquipmentCategory {
    return {
      normal: category.normal
        ? {
            flexibleRating: category.normal.ratings.rating,
            selections: getInitialAnswersForAllModules(groupQuestions, scope, offer, groupId, 'normal', isDXEnabled)
          }
        : null,
      critical: null
    }
  }

  const groups = match(offering.groups, {
    DoorGroups: _ => ({}),
    ElevatorEscalatorApfGroups: (gs: Groups<ElevatorsEscalatorsApfs>) =>
      R.map(
        g => ({
          salesToolId: g.salesToolId,
          name: g.name,
          groupKind: g.groupKind,
          selections: g.equipment.elevator
            ? buildCategory(g.equipment.elevator, g.salesToolId)
            : { normal: null, critical: null }
        }),
        gs
      )
  })

  if (!doesGroupsHasPeopleflowGroup(offering as Flexible) && offering.interaction) {
    return new QuickTenderWithRatings(
      groups,
      scope,
      getInitialAnswersForInteraction(interactionQuestions, scope, offer),
      offering.interaction.scope
    )
  } // Error message not meant for end user, just to handle else case for type error
  throw new Error('Cannot change from Fleible to a QuickTender with APF equipments')
}

export interface FlexibleGroupsPattern<A> {
  DoorGroups: (groups: Groups<Doors>) => A
  ElevatorEscalatorApfGroups: (groups: Groups<ElevatorsEscalatorsApfs>) => A
}

export function match<A>(groups: ElevatorEscalatorApfGroups | DoorGroups, pattern: FlexibleGroupsPattern<A>) {
  switch (groups._tag) {
    case 'DoorGroups':
      return pattern.DoorGroups(groups.groups)
    case 'ElevatorEscalatorApfGroups':
      return pattern.ElevatorEscalatorApfGroups(groups.groups)
  }
}
