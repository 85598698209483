import * as ClassNames from 'classnames'
import * as React from 'react'

interface Props {
  className?: string
  isDisabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export default class Circles extends React.PureComponent<Props, Record<string, never>> {
  constructor(props: Props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault()
    if (!this.props.isDisabled && typeof this.props.onClick === 'function') {
      this.props.onClick(event)
    }
  }

  render() {
    const { className, isDisabled } = this.props
    return (
      <div className={ClassNames('circles', className, { isDisabled })} onClick={this.onClick}>
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
      </div>
    )
  }
}
