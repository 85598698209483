import * as R from 'ramda'
import { browserHistory } from 'react-router'

import * as db from './db/db'
import * as dialog from './nemo/dialog'

import WebWorkers from './web-worker/clients'
import * as ServiceWorker from './service-worker/client'
import { IsOnline, IsUpgradeOn } from './state/global'
import { CurrentOffer, mergeOfferChanges } from './state/current-offer'
import { OpportunitySfId } from '../../types/types'
import { hide } from './nemo/dialog'
import { renderOfferVersionOutdatedDialog } from './components/common/Dialog/OfferVersionOutdatedDialog'
import { renderOfferVersionSentToSAPDialog } from './components/common/Dialog/OfferVersionSentToSAPDialog'

export const init = () => {
  WebWorkers.init()
}

const login = () => {
  WebWorkers.terminate()
  ServiceWorker.logout().then(() => (window.location.href = '/login'))
}

const handleVersionMismatch = () => {
  WebWorkers.terminate()
  ServiceWorker.unregister().then(() => location.reload(true))
}

const opportunitiesChanged = (opportunityIds: OpportunitySfId[]) => {
  const currentOpportunityId = sessionStorage.getItem('opportunityId')

  if (currentOpportunityId != null && R.contains(currentOpportunityId, opportunityIds)) {
    db.getOfferByOpportunityId(currentOpportunityId).fork(
      (err: Error) =>
        dialog.error({
          name: err.name,
          message: err.message,
          metaData: {
            cause: err
          }
        }),
      updatedOffer =>
        CurrentOffer.update(offer => (updatedOffer != null ? mergeOfferChanges(offer, updatedOffer) : offer))
    )
  }
}

const openPdfContract = (url: string) => {
  /* Hide loading dialog */
  hide()
  /* Open new window from PDF url */
  window.open(url)
}

const sapContractCreated = () => {
  /* Hide loading dialog */
  hide()
}

const viewOfferVersionOutdatedDialog = () => {
  dialog.show(renderOfferVersionOutdatedDialog())
}

const viewOfferVersionSentToSAPDialog = () => {
  dialog.show(renderOfferVersionSentToSAPDialog())
}

export class FromWindow {
  static login = login
  static handleVersionMismatch = handleVersionMismatch

  static logout = (): Promise<any> => {
    WebWorkers.terminate()
    return ServiceWorker.logout().then(() => browserHistory.push('/logged-out'))
  }
}

export class FromOtherTab {
  static logout = () => {
    WebWorkers.terminate()
    browserHistory.push('/logged-out')
  }
}

export class FromWebWorkers {
  static login = login
  static handleVersionMismatch = handleVersionMismatch
  static opportunitiesChanged = opportunitiesChanged
  static openPdfContract = openPdfContract
  static viewOfferVersionOutdatedDialog = viewOfferVersionOutdatedDialog
  static viewOfferVersionSentToSAPDialog = viewOfferVersionSentToSAPDialog
  static sapContractCreated = sapContractCreated
  static onlineState(isOnline: boolean, isUpgradeOn: boolean) {
    IsOnline.set(isOnline)
    IsUpgradeOn.set(isUpgradeOn)
  }
}

export class FromServiceWorker {
  static login = login
  static handleVersionMismatch = handleVersionMismatch
  static opportunitiesChanged = opportunitiesChanged
}
