import * as R from 'ramda'
import * as Task from 'data.task'
import { version } from '../../../common/version.json'
import { trackTiming } from '../analytics-tracker'
import { ClockDriftData } from '../../../types/types'
import * as dialog from '../nemo/dialog'
import { t } from 'i18next'
import { AutoFetchOpportunities } from '../state/global'
import { browserHistory } from 'react-router'

const requestIdHeader = 'X-Request-Id'

export class AjaxError extends Error {
  readonly _tag = 'AjaxError'
  constructor(
    readonly status: number,
    readonly statusText: string,
    readonly data: any,
    readonly requestId: string | null
  ) {
    super(`${status}: ${statusText}`)
  }
}

AjaxError.prototype.name = 'AjaxError'

export class SalesforceError extends Error {
  readonly _tag = 'SalesforceError'
  constructor(
    readonly status: number,
    readonly statusText: string,
    readonly message: string,
    readonly data: any,
    readonly requestId: string | null
  ) {
    super(`${status}: ${statusText}`)
  }
}
SalesforceError.prototype.name = 'SalesforceError'
export class CacheExpireError extends Error {
  readonly _tag = 'CacheExpireError'
  constructor(
    readonly status: number,
    readonly statusText: string,
    readonly message: string,
    readonly data: any,
    readonly requestId: string | null
  ) {
    super(`${status}: ${statusText}`)
  }
}
CacheExpireError.prototype.name = 'CacheExpireError'
export class NetworkError extends Error {
  readonly _tag = 'NetworkError'
}
NetworkError.prototype.name = 'NetworkError'

class TimeoutError extends Error {
  readonly _tag = 'TimeoutError'
}
TimeoutError.prototype.name = 'TimeoutError'

class UnauthorizedError extends Error {
  readonly _tag = 'UnauthorizedError'
}
UnauthorizedError.prototype.name = 'UnauthorizedError'

class VersionMismatchError extends Error {
  readonly _tag = 'VersionMismatchError'
}
VersionMismatchError.prototype.name = 'VersionMismatchError'

export class ClockError extends Error {
  readonly _tag = 'ClockError'
  constructor(readonly message: string, readonly data: ClockDriftData) {
    super(message)
  }
}
ClockError.prototype.name = 'ClockError'

// when user don't have permission but permited to go around in app.
export class SalesforcePermissionError extends Error {
  readonly _tag = 'SalesforcePermissionError'
  constructor(
    readonly status: number,
    readonly statusText: string,
    readonly message: string,
    readonly data: any,
    readonly requestId: string | null
  ) {
    super(`${status}: ${statusText}`)
  }
}
SalesforcePermissionError.prototype.name = 'SalesforcePermissionError'

export type HttpClientError =
  | AjaxError
  | NetworkError
  | TimeoutError
  | UnauthorizedError
  | VersionMismatchError
  | SalesforceError
  | SalesforcePermissionError
  | ClockError
  | CacheExpireError

export interface AjaxRequestParameters {
  path: string
  data?: any
  timeout?: number
  url?: string
  contentType?: string
  cacheControl?: string
}

// see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
type FetchResponse = Response | TypeError
type MakeRequestResponse = FetchResponse | TimeoutError
const isNetworkError = (r: MakeRequestResponse): r is TypeError => r instanceof TypeError
const isTimeout = (r: MakeRequestResponse): r is TimeoutError => r instanceof TimeoutError

const baseUrl = `${typeof location !== 'undefined' ? location.origin : ''}/api/`

const currentPath = () => location.href.replace(location.origin, '')

export function makeRequest<T>(method: 'GET' | 'POST', params: AjaxRequestParameters) {
  const { path, data, timeout, url, contentType, cacheControl } = params
  const fullUrl = url ? url : `${baseUrl}${path}`
  return new Task<T>((reject, resolve) => {
    const headers = R.mergeAll([
      { path: currentPath(), version },
      cacheControl ? { 'Cache-Control': cacheControl } : undefined,
      contentType ? { 'Content-Type': contentType } : undefined
    ])

    const body = contentType === 'application/json' ? JSON.stringify(data) : data
    const options = R.pipe(o => (method === 'POST' ? R.assoc('body', body, o) : o))({
      method,
      headers: new Headers(headers),
      credentials: 'same-origin'
    }) as RequestInit

    let timeoutId: NodeJS.Timer
    const cancelTimeout = (response: FetchResponse) => {
      clearTimeout(timeoutId)
      return response
    }

    const requestStart = Date.now()
    Promise.race([
      fetch(fullUrl, options),
      new Promise((_, rejectPromise) => {
        timeoutId = setTimeout(() => rejectPromise(new TimeoutError('request timeout')), timeout || 90000)
      })
    ])
      .then(cancelTimeout)
      .then(trackNetworkTime(path, requestStart))
      .then(throwOnNotOk)
      .then(bodyFrom)
      .then(resolve)
      .catch((errorResponse: MakeRequestResponse) => toError(errorResponse).then(reject))
  })
}

function trackNetworkTime(path: string, startTime: number) {
  return (response: FetchResponse) => {
    const time = Date.now() - startTime
    trackTiming('HTTP request', path, time)
    return response
  }
}

function throwOnNotOk(response: Response) {
  if (response.ok) {
    return response
  }
  throw response
}

function toError(response: MakeRequestResponse): Promise<HttpClientError> {
  if (isTimeout(response)) {
    return Promise.resolve(response)
  }

  if (isNetworkError(response)) {
    return Promise.resolve(new NetworkError())
  }

  switch (response.status) {
    case 401:
      return Promise.resolve(new UnauthorizedError())
    case 412:
      return Promise.resolve(new VersionMismatchError())
    default:
      return bodyFrom(response).then((body: any) => {
        if (typeof body === 'object' && 'name' in body && 'message' in body && body.name === 'SalesforceError') {
          const permissionError = 'Please reference your WSDL or the describe call for the appropriate names.'
          if (body.message && body.message.includes(permissionError)) {
            AutoFetchOpportunities.set(false)
            dialog.error(
              {
                name: t('customer.salesforePermissionError.title'),
                message: t('customer.salesforePermissionError.message'),
                metaData: {
                  cause: body.message
                }
              },
              () => browserHistory.push('/')
            )
            return new SalesforcePermissionError(
              response.status,
              response.statusText,
              body.message,
              body,
              response.headers.get(requestIdHeader)
            )
          }
          return new SalesforceError(
            response.status,
            response.statusText,
            body.message,
            body,
            response.headers.get(requestIdHeader)
          )
        } else if (body.message.includes('no cached data available for')) {
          dialog.error(
            {
              name: t('customer.cachedExpireDataError.message'),
              metaData: {
                cause: body.message
              }
            },
            () => browserHistory.push('/')
          )
          return new CacheExpireError(
            response.status,
            response.statusText,
            body.message,
            body,
            response.headers.get(requestIdHeader)
          )
        }
        return new AjaxError(response.status, response.statusText, body, response.headers.get(requestIdHeader))
      })
  }
}

function bodyFrom(response: Response): Promise<any> {
  function getParseMethod(contentType: string | null) {
    const type = (contentType || '').replace('; charset=utf-8', '')
    switch (type) {
      case 'application/json':
        return 'json'
      case 'application/pdf':
      case 'application/xml':
        return 'blob'
      default:
        return 'text'
    }
  }

  const parseMethod = getParseMethod(response.headers.get('Content-Type'))
  return response.status === 204 ? Promise.resolve(null) : response[parseMethod]()
}
