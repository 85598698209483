import { t } from 'i18next'
import * as R from 'ramda'
import * as Ru from '../../../common/ramda-utils'
import {
  AssessmentEquipmentAnswers,
  AssessmentEquipmentGroupAnswers,
  Building,
  BuildingDetails,
  BuildingStId,
  Criticality,
  Equipment,
  EquipmentCriticality,
  EquipmentGroup,
  EquipmentGroups,
  InitialData,
  Offer,
  Price,
  PriceDetails,
  PricingProfile
} from '../../../types/types'

interface OldPrice extends Price {
  readonly buildingId: BuildingStId
  readonly type: EquipmentCriticality
}

function convertSalesToolIdToUUID(salesToolId: string): string {
  switch (salesToolId.length) {
    case 61:
      return salesToolId.slice(-36)
    case 36:
      return salesToolId
    default:
      throw new Error(`Invalid salesToolId: ${salesToolId}`)
  }
}

function convertBuildings(buildings: any[], assessmentAnswers: any): Building[] {
  return buildings.map(building => {
    const equipment: Equipment[] = building.details.equipment.map((e: any) => {
      const criticalityAnswer = R.path<number[], number>(
        [building.salesToolId, e.salesToolId, 'CRITICALITY_FOR_CUSTOMERS', 'choice'],
        assessmentAnswers
      )
      const criticality = criticalityAnswer >= 3 ? 'critical' : 'normal'
      return {
        ...e,
        criticality,
        buildingId: convertSalesToolIdToUUID(building.salesToolId),
        salesToolId: convertSalesToolIdToUUID(e.salesToolId),
        groupId: convertSalesToolIdToUUID(building.salesToolId)
      }
    })
    const details: BuildingDetails = {
      ...building.details,
      equipment,
      salesToolId: convertSalesToolIdToUUID(building.salesToolId)
    }
    const newBuilding: Building = {
      details,
      salesToolId: convertSalesToolIdToUUID(building.salesToolId)
    }
    return newBuilding
  })
}

function convertEquipmentGroups(buildings: any[]): EquipmentGroups {
  const equipmentGroups = buildings.map(building => {
    const normalLifts =
      building.normalLifts.equipmentIds.length > 0
        ? {
            selections: building.normalLifts.selections,
            ratings: building.normalLifts.ratings
          }
        : undefined
    const criticalLifts =
      building.criticalLifts.equipmentIds.length > 0
        ? {
            selections: building.criticalLifts.selections,
            ratings: building.criticalLifts.ratings
          }
        : undefined
    const groupings = {
      elevator: {
        ...(normalLifts && { normal: normalLifts }),
        ...(criticalLifts && { critical: criticalLifts })
      }
    }
    const equipmentGroup: EquipmentGroup = {
      name: building.details.buildingAddress || t('building.noAddress'),
      salesToolId: convertSalesToolIdToUUID(building.salesToolId),
      groupKind: 'elevators_and_escalators',
      ...groupings
    }
    return equipmentGroup
  })
  return R.indexBy(equipmentGroup => equipmentGroup.salesToolId, equipmentGroups)
}

function convertPriceProfile(priceProfile: any): any {
  const equipmentCriticality: Criticality = priceProfile.type === 'criticalLifts' ? 'critical' : 'normal'
  const priceProfileWithoutRemovedFields: PricingProfile = {
    ...priceProfile,
    equipmentCriticality,
    groupId: convertSalesToolIdToUUID(priceProfile.buildingId)
  }

  return Ru.pipe(priceProfileWithoutRemovedFields)(
    p => R.dissoc('buildingId', p),
    p => R.dissoc('type', p)
  )
}

function convertPriceDetails(priceDetails: PriceDetails): any {
  const profile: PricingProfile = convertPriceProfile(priceDetails.profile)

  return {
    ...priceDetails,
    profile
  }
}

function convertPrices(prices: OldPrice[]): Price[] {
  return prices.map(price => {
    const priceWithoutRemovedFields = {
      ...price,
      groupId: convertSalesToolIdToUUID(price.buildingId),
      id: convertSalesToolIdToUUID(price.id),
      priceDetails: convertPriceDetails(price.priceDetails)
    }

    return Ru.pipe(priceWithoutRemovedFields)(
      p => R.dissoc('buildingId', p),
      p => R.dissoc('type', p)
    )
  })
}

function convertAssessmentEquipmentAnswers(assessmentAnswers: any): AssessmentEquipmentAnswers {
  const pairs: any = R.chain(
    ([_, combinedAnswers]) =>
      R.toPairs(combinedAnswers)
        .filter(([equipmentId]) => equipmentId !== 'building')
        .map(([equipmentId, equipmentAnswers]) => [
          convertSalesToolIdToUUID(equipmentId as string),
          R.map(answer => answer.choice, equipmentAnswers)
        ]),
    R.toPairs(assessmentAnswers)
  )

  return R.fromPairs(pairs)
}

function convertAssessmentEquipmentGroupAnswers(assessmentAnswers: any): AssessmentEquipmentGroupAnswers {
  const pairs: any = R.chain(
    ([buildingId, combinedAnswers]) =>
      R.toPairs(combinedAnswers)
        .filter(([equipmentId]) => equipmentId === 'building')
        .map(([_, buildingAnswers]) => [
          convertSalesToolIdToUUID(buildingId as string),
          R.map(answer => answer.choice, buildingAnswers)
        ]),
    R.toPairs(assessmentAnswers)
  )

  return R.fromPairs(pairs)
}

function convertInitialData(initialData: any): InitialData {
  return {
    ...initialData,
    prices: R.fromPairs(
      R.toPairs(initialData.prices).map(([id, price]) => [convertSalesToolIdToUUID(id as string), price] as any)
    ),
    selections: R.fromPairs(
      R.toPairs(initialData.selections).map(
        ([id, selections]) => [convertSalesToolIdToUUID(id as string), selections] as any
      )
    )
  }
}

export default function (offer: any): Offer {
  offer.assessmentEquipmentAnswers = convertAssessmentEquipmentAnswers(offer.assessmentAnswers)
  offer.assessmentEquipmentGroupAnswers = convertAssessmentEquipmentGroupAnswers(offer.assessmentAnswers)
  offer.equipmentGroups = convertEquipmentGroups(offer.buildings)
  offer.prices = convertPrices(offer.prices)
  offer.buildings = convertBuildings(offer.buildings, offer.assessmentAnswers)
  offer.initialData = convertInitialData(offer.initialData)
  delete offer.assessmentAnswers
  return offer
}
