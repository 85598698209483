import * as R from 'ramda'
import { validation } from 'fp-ts'
import {
  Criticality,
  EquipmentGroupStId,
  InteractionScope,
  Offer,
  OfferingInteractionQuestion,
  OfferSelection,
  OfferSelectionType,
  SapKey,
  Scope,
  Selection,
  Selections
} from '../../../../types/types'
import {
  AnswersAndChoiceStates,
  ChoiceState,
  Dependency,
  DependencyInputs,
  DependencyQuestion,
  OfferingOrInteractionQuestion,
  QuestionChoiceState,
  SetHidden,
  Required,
  Switch,
  Test,
  Toggle,
  ValidationResult,
  DependencyKey,
  TechnicalDependencyKey,
  Expr
} from './types'
import { Code as CodeError, DependencyError, No as NoError, And as AndError, Or as OrError } from './error'
import { getEquipmentForGroup } from '../offer'

const { success } = validation
const failure = validation.failure({ concat: (x: DependencyError) => (y: DependencyError) => x.concat(y) })

const answerIsAllowed = (
  key: DependencyKey,
  choices: string[],
  dependencyInputs: DependencyInputs,
  equipmentCheckFunction: any
) => {
  const hasAnswer = (answer: Selection) =>
    Array.isArray(answer)
      ? !R.isEmpty(R.intersection(answer as string[], choices || []))
      : R.find((v: string) => v === answer, choices) !== undefined

  // Separate handling for SAP keys and technical dependencies.
  // In case of technical dependencies, all equipment must be
  // checked so that it's not possible to sell invalid services
  // to groups with mixed technical details.

  const EquipmentForGroup = (dependencyInput: any) =>
    // equipmentCheckFunction has to be supplied because we use separate checks in case of answer and notAnswer.
    // When dependency type is 'answer' we should check if every equipment in group (with same criticality) matches
    // the required technical platform.
    // When dependency type is 'notAnswer', because of the nature of 'notAnswer' check, we have to check if there is
    // at least one equipment with the defined technical platform.
    getEquipmentForGroup(dependencyInput.groupId!, dependencyInput.offer.buildings).filter(equipment =>
      dependencyInput.criticality ? equipment.criticality === dependencyInput.criticality : true
    )

  switch (key) {
    case TechnicalDependencyKey.technicalPlatform:
      if (dependencyInputs.groupId) {
        // Criticality is not always defined, in that case run the check for every equipment in the group
        const groupEquipment = EquipmentForGroup(dependencyInputs)
        return equipmentCheckFunction(
          (equipment: any) => equipment.technicalPlatform !== null && choices.includes(equipment.technicalPlatform),
          groupEquipment
        )
      }
      return false

    case TechnicalDependencyKey.apfComponent:
      if (dependencyInputs.groupId) {
        const groupEquipment = EquipmentForGroup(dependencyInputs)
        return equipmentCheckFunction(
          (equipment: any) =>
            equipment.equipmentCategory !== 'elevator' ||
            (!R.isNil(equipment.apfComponent) &&
              equipment.apfComponent
                .split(',')
                .map((v: string) => choices.includes(v))
                .some((e: boolean) => e === true)),
          groupEquipment
        )
      }
      return false

    case TechnicalDependencyKey.koneConnectivityDeviceType:
      if (dependencyInputs.groupId) {
        const groupEquipment = EquipmentForGroup(dependencyInputs)
        return equipmentCheckFunction(
          (equipment: any) =>
            equipment.equipmentCategory !== 'elevator' ||
            (!R.isNil(equipment.koneConnectivityDeviceType) &&
              equipment.koneConnectivityDeviceType
                .split(',')
                .map((v: string) => choices.includes(v))
                .some((e: boolean) => e === true)),
          groupEquipment
        )
      }
      return false

    default:
      return R.has(key, dependencyInputs.answers) && hasAnswer(dependencyInputs.answers[key] as Selection)
  }
}

// Compile tests for dependency validations
// Returns a Test which is used to validate answers
export const compile =
  (expr: Expr): Test =>
  (inputs: DependencyInputs) =>
    validateExpr(expr, inputs)

export const validateExpr = (expr: Expr, inputs: DependencyInputs): ValidationResult => {
  switch (expr[0]) {
    case 'answer': {
      const key = expr[1]
      const choices = expr[2]
      if (answerIsAllowed(key, choices, inputs, R.all)) {
        return success(true)
      } else {
        return failure(new CodeError(key))
      }
    }
    case 'notAnswer': {
      const key = expr[1]
      const choices = expr[2]
      if (answerIsAllowed(key, choices, inputs, R.any)) {
        return failure(new CodeError(key))
      } else {
        return success(true)
      }
    }
    case 'noanswer': {
      const { answers } = inputs
      const key = expr[1]
      if (!R.has(key, answers) || answers[key] === null) {
        return success(true)
      } else {
        return failure(new CodeError(key))
      }
    }
    case 'some': {
      const { answers } = inputs
      const key = expr[1]
      if (
        R.has(key, answers) &&
        Array.isArray(answers[key]) &&
        !R.isEmpty(answers[key]) &&
        !R.equals(answers[key], ['0'])
      ) {
        return success(true)
      } else {
        return failure(new CodeError(key))
      }
    }
    case 'none': {
      const { answers } = inputs
      const key = expr[1]
      if (
        R.has(key, answers) &&
        Array.isArray(answers[key]) &&
        (R.isEmpty(answers[key]) || R.equals(answers[key], ['0']))
      ) {
        return success(true)
      } else {
        return failure(new CodeError(key))
      }
    }
    case 'and': {
      const a = validateExpr(expr[1], inputs)
      const b = validateExpr(expr[2], inputs)
      return a.isSuccess() ? b : b.isSuccess() ? a : failure(new AndError([a.value, b.value]))
    }
    case 'or': {
      const a = validateExpr(expr[1], inputs)
      const b = validateExpr(expr[2], inputs)
      return a.isSuccess() ? a : b.isSuccess() ? b : failure(new OrError(a.value, b.value))
    }
    case 'any': {
      const exprs = expr[1]
      if (exprs.length === 0) {
        return failure(new CodeError('fail'))
      }
      const [first, ...rest] = exprs
      return validateExpr(
        R.reduce((curExpr, nextExpr) => ['or', nextExpr, curExpr], first, rest),
        inputs
      )
    }
  }
}

export const compileDependencies = (questions: OfferSelection[]): OfferingOrInteractionQuestion[] =>
  questions.map(question => {
    const { key, dependencies, selections } = question
    const deps = dependencies.map(dependency => {
      switch (dependency.action) {
        case 'toggle':
          return new Toggle(dependency.values!, compile(dependency.expr))
        case 'set':
          return new SetHidden(key, dependency.value!, compile(dependency.expr))
        case 'switch':
          return new Switch(key, dependency.values!, compile(dependency.expr))
        case 'required':
          // The required dependency works a bit differently than the others:
          // for other dependency types a failure in the expression means that
          // there is an error, but for the required-dependency a success value
          // means that the question will be marked as required and thus should
          // return a failure here. What we do here is flip the result from the
          // expression.
          const dep = compile(dependency.expr)
          return new Required((inputs: DependencyInputs) => {
            const res = dep(inputs)
            return res.isSuccess() ? failure(new CodeError('scope')) : success(true)
          }, dependency.values)
        default:
          throw new Error('Not a valid dependency type: ' + JSON.stringify(dependency))
      }
    })
    return { ...question, dependencies: deps, choices: availableChoices(selections) }
  })

// Applies default choice states to given choices before applying dependencies to state
export const applyDefaultChoiceDependencies = (
  choices: string[],
  dependencies: Dependency[],
  answers: Selections,
  offer: Offer,
  groupId: EquipmentGroupStId | null,
  criticality: Criticality | null
) => {
  const defaultChoice = (value: string): ChoiceState => ({
    value,
    enabled: true,
    required: false,
    checked: false,
    failureReasons: new NoError()
  })
  const choicesDefault = choices.map(defaultChoice)
  return applyDependenciesToState(choicesDefault, dependencies, answers, offer, groupId, criticality)
}

export const applyDependenciesToState = (
  initialStates: ChoiceState[],
  dependencies: Dependency[],
  initialAnswers: Selections,
  offer: Offer,
  groupId: EquipmentGroupStId | null,
  criticality: Criticality | null
): { answers: Selections; choices: ChoiceState[] } =>
  dependencies.reduce(
    ({ answers, choices }: { answers: Selections; choices: ChoiceState[] }, dependency: Dependency) => {
      const inputs: DependencyInputs = { answers, offer, groupId, criticality }

      switch (dependency._tag) {
        case 'toggle': {
          const { affectedChoices, test } = dependency
          const res = test(inputs)
          const newChoices = choices.map(({ checked, value, required, enabled: prevEnabled, failureReasons }) => {
            const isAffected = R.contains(value, affectedChoices)
            return {
              checked,
              value,
              enabled: isAffected ? res.isSuccess() && prevEnabled : prevEnabled,
              required,
              failureReasons: isAffected
                ? !res.isSuccess()
                  ? failureReasons.concat(res.value as DependencyError)
                  : failureReasons
                : failureReasons
            }
          })
          return { answers, choices: newChoices }
        }
        case 'set': {
          const { questionKey, test, value } = dependency
          const res = test(inputs)
          const newAnswers = res.isSuccess() ? { ...answers, [questionKey]: value } : answers
          return { answers: newAnswers, choices }
        }
        case 'switch': {
          const { questionKey, test, values } = dependency
          const value = test(inputs).isSuccess() ? values[0] : values[1]
          return {
            answers: { ...answers, [questionKey]: value },
            choices
          }
        }
        case 'required':
        default: {
          const { test, values } = dependency
          const res = test(inputs)
          const affectedChoices = values || choices.map(c => c.value)
          const choiceStates = res.isFailure()
            ? choices.map(c => (R.contains(c.value, affectedChoices) ? { ...c, required: true } : c))
            : choices
          return { answers, choices: choiceStates }
        }
      }
    },
    { answers: initialAnswers, choices: initialStates }
  )

export const getScopeDependency = (choices: string[], allowedChoices: string[]): Dependency =>
  new Toggle(R.difference(choices, allowedChoices), () => failure(new CodeError('scope')))

const getRequiredDependency = (
  key: SapKey,
  choices: string[],
  type: OfferSelectionType,
  optional: boolean
): Dependency =>
  new Required((inputs: DependencyInputs) => {
    const { answers } = inputs
    const answer = answers[key]

    if (optional) {
      return success(true)
    } else if (type === 'multiple' && Array.isArray(answer)) {
      return R.any(
        Boolean,
        answer.map(v => R.contains(v, choices))
      )
        ? success(true)
        : failure(new CodeError('scope'))
    } else {
      return R.contains(answer, choices) ? success(true) : failure(new CodeError('scope'))
    }
  }, undefined)

// A kind of a temporary fix. If a question doesn't have an answer
// we will give it the default value. This is only for old offers
// which were created before all questions had default values.
const fixAnswers = (
  { key, default_values, optional }: OfferingOrInteractionQuestion,
  answers: Selections,
  scope: Scope
): Selections =>
  hasMissingAnswer(optional, key, answers)
    ? { ...answers, [key]: getDefaultValue(optional, default_values[scope] as Selection) }
    : answers

const getDefaultValue = (optional: boolean, defaultValue: Selection): Selection => {
  if (optional) {
    return defaultValue === undefined ? null : defaultValue
  } else {
    return defaultValue
  }
}

const hasMissingAnswer = (optional: boolean, key: SapKey, answers: Selections): boolean =>
  !R.has(key, answers) || (!optional && (answers[key] === null || R.equals(answers[key], [])))

export const getChoiceStates = (
  questions: DependencyQuestion[],
  offer: Offer,
  groupId: EquipmentGroupStId,
  criticality: Criticality | null,
  initAnswers: Selections,
  initStates?: AnswersAndChoiceStates
): AnswersAndChoiceStates =>
  questions.reduce(
    ({ answers, states }, question) => {
      const { choices, dependencies, key, optional, scope, selections, type } = question
      const scopeDep = getScopeDependency(choices, R.propOr([], scope, selections))
      const required = getRequiredDependency(key, choices, type, optional)
      const allDependencies = [required].concat([scopeDep]).concat(dependencies)
      const newStates = applyDefaultChoiceDependencies(
        choices,
        allDependencies,
        fixAnswers(question, answers, scope),
        offer,
        groupId,
        criticality
      )

      return {
        answers: newStates.answers,
        states: { ...states, [key]: newStates.choices }
      }
    },
    { answers: initAnswers, states: initStates || {} }
  )

export const getInteractionChoiceStates = (
  questions: OfferingInteractionQuestion[],
  scope: InteractionScope,
  interactionAnswers: Selections,
  answersByGroup: Record<EquipmentGroupStId, Selections[]>,
  offer: Offer
): AnswersAndChoiceStates => {
  const interactionStates = (answers: Selections) =>
    questions.reduce<AnswersAndChoiceStates>(
      ({ answers: curAnswers, states }, question) => {
        const { key, type, choices, selections, optional } = question

        const scopeDep = getScopeDependency(choices, R.propOr([], scope, selections))
        const required = getRequiredDependency(key, choices, type, optional)
        const dependencies = [required].concat([scopeDep])
        const newStates = applyDefaultChoiceDependencies(
          choices,
          dependencies,
          fixAnswers(question, curAnswers, scope),
          offer,
          null,
          null
        )

        return {
          answers: newStates.answers,
          states: { ...states, [key]: newStates.choices }
        }
      },
      { answers, states: {} }
    )

  const interactionQuestionKeys = questions.map(q => q.key)
  const answersWithGroupId = R.unnest(
    R.values(R.mapObjIndexed((answers, groupId) => answers.map(a => ({ groupId, answers: a })), answersByGroup))
  )

  const statesForBuildings = (states: AnswersAndChoiceStates) =>
    answersWithGroupId.reduce(
      ({ answers: groupAnswers, states: groupStates }, { groupId, answers }) =>
        questions.reduce(
          ({ answers: qAnswers, states: qStates }, { key, dependencies }) => {
            const withGroupAnswers = R.merge(answers, qAnswers)
            const newStates = applyDependenciesToState(
              qStates[key]!,
              dependencies,
              withGroupAnswers,
              offer,
              groupId,
              null
            )

            return {
              answers: R.pick(interactionQuestionKeys, newStates.answers),
              states: { ...qStates, [key]: newStates.choices }
            }
          },
          { answers: groupAnswers, states: groupStates }
        ),
      states
    )

  const finalStates = statesForBuildings(interactionStates(interactionAnswers))
  return { answers: finalStates.answers, states: finalStates.states }
}

export const applySetterDependencies = (
  questions: OfferingOrInteractionQuestion[],
  initAnswers: Selections,
  offer: Offer,
  groupId: EquipmentGroupStId | null,
  criticality: Criticality | null
): Selections =>
  questions.reduce((answers, question) => {
    const { dependencies, choices } = question
    // we only care about those dependencies which potentially set new
    // values for selections
    const onlyHidden = dependencies.filter(d => d._tag === 'set' || d._tag === 'switch')
    return applyDefaultChoiceDependencies(choices, onlyHidden, answers, offer, groupId, criticality).answers
  }, initAnswers)

const answerIsInvalid = (type: OfferSelectionType, state: ChoiceState[], answer: Selection): boolean => {
  const disabled = state.filter(c => !c.enabled).map(c => c.value)

  const requiredChoices: string[] = state.filter(c => c.required).map(c => c.value)
  const noAnswerToRequired = !R.isEmpty(requiredChoices)
    ? type === 'multiple' && Array.isArray(answer)
      ? !R.all((v: string) => R.contains(v, answer), requiredChoices)
      : R.none((v: string) => answer === v, requiredChoices)
    : false

  return Array.isArray(answer) && type === 'multiple'
    ? R.any(
        Boolean,
        answer.map(v => R.contains(v, disabled))
      ) || noAnswerToRequired
    : R.contains(answer, disabled) || noAnswerToRequired
}

export const getQuestionUiState = (
  question: OfferingOrInteractionQuestion,
  state: ChoiceState[],
  answers: Selections
): QuestionChoiceState => {
  const { key, choices, type } = question
  const getOnValue = (choiceValues: string[]) => (R.contains('Y', choiceValues) ? 'Y' : '1')
  const onValue = getOnValue(choices)
  const answer = answers[key] || null
  // a boolean question is enabled when the alternative choice is enabled
  const possibleAlternative = state.filter(c => c.value !== answer && c.enabled)
  const enabled = type === 'boolean' ? possibleAlternative.length > 0 : false

  // a boolean question is checked when its "on" value is
  // the current answer
  const checked = type === 'boolean' ? onValue && onValue === answer : false

  // adds a checked flag for a radio or multiple answer choice
  const addChecked = (c: ChoiceState): ChoiceState => {
    const isChecked = answer
      ? type === 'multiple' && Array.isArray(answer)
        ? typeof answer.find(v => v === c.value) !== 'undefined'
        : answer === c.value
      : false
    return { ...c, checked: isChecked }
  }

  const fixRequired = (c: ChoiceState): ChoiceState =>
    Array.isArray(answer) && type === 'multiple'
      ? R.contains(c.value, answer)
        ? { ...c, required: false }
        : c
      : null !== answer // eslint-disable-line yoda
      ? { ...c, required: false }
      : c

  return {
    choices: type !== 'boolean' ? state.map(s => addChecked(fixRequired(s))) : state,
    hasValidAnswer: !answerIsInvalid(type, state, answer),
    enabled,
    checked,
    value: answer
  }
}

// Get all available choices for a question.
const availableChoices = (selections: PRecord<Scope, string[]>): string[] => {
  const choices: string[] = R.uniq(R.flatten(R.values(selections) as string[][]))
  // split into things that look like numbers and things that don't
  const [other, numbers]: [string[], string[]] = R.splitWhen((n: string) => /^[\d.]+$/.test(n), choices)
  const sort = R.sortBy((s: string) => s)
  const sortNumbers = R.sort((a: string, b: string) => {
    const first = parseFloat(a)
    const second = parseFloat(b)
    if (first === second) {
      return 0
    } else if (first > second) {
      return 1
    } else {
      return -1
    }
  })
  return sort(other).concat(sortNumbers(numbers))
}

export const findChoiceState = (choice: string, states: ChoiceState[]): ChoiceState => {
  const res = R.find((c: ChoiceState) => c.value === choice, states)
  if (res === undefined) {
    throw new Error('Choice ' + choice + ' was not found in state')
  } else {
    return res
  }
}

export const filterChoicesToRender = (type: OfferSelectionType, choices: string[]): string[] =>
  type === 'multiple' ? choices.filter(c => c !== '0') : choices
