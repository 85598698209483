import * as Task from 'data.task'
import * as db from '../db/db'
import * as ui from '../ajax/ui'
import * as Moment from 'moment'
import { IsOnline, IsSyncInProgress, UnsavedOffersCount } from '../state/global'
import { syncOffer, SyncResult } from './sync-offer'
import { sequence } from '../../../common/task'
import { handleError, getErrorName } from '../ui-thread'
import { CurrentOffer } from '../state/current-offer'
import { Offer } from '../../../types/types'
import { isOfferFinalized } from '../../../common/offer-state-predicates'
import { HttpClientErrorHolder } from '../ajax/ui'
import { AjaxError } from '../ajax/http-client'

// Global sync state
let SYNC_IN_PROGRESS = false

class SyncError extends Error {
  constructor(readonly failures: SyncResult[]) {
    super()
  }
}

// Returns a sync method when called will sync all unsynced offers.
// tabId identificates browser tab where sync originated
function getSync(unSyncedOffers: Offer[]): () => Task<void> {
  // Construct an update function where
  // we hook correct side effects
  const syncOfferF = syncOffer(
    db.updateOffer,
    ui.saveOfferToBackend,
    CurrentOffer.update.bind(CurrentOffer),
    UnsavedOffersCount.update.bind(UnsavedOffersCount)
  )

  let startTime = 0

  return () => {
    if (!IsOnline.get()) {
      console.info('Application is offline. Skipping sync.')
      return Task.of()
    }
    if (SYNC_IN_PROGRESS) {
      console.info('Sync in progress. Skipping sync.')
      return Task.of()
    }

    SYNC_IN_PROGRESS = true
    startTime = new Date().getTime()
    IsSyncInProgress.set(true)
    console.info('Sync started')

    const tasks = unSyncedOffers.map(offer => syncOfferF(offer))
    return sequence(tasks)
      .rejectedMap(error => {
        // Do not reject here, as it will cause uncatched promise exception
        // that clutters Bugsnag. If we end up here, it means that something
        // major failed with the Task chain. When individual sync fails,
        // it will not end up here, but into success branch.
        const duration = new Date().getTime() - startTime
        console.error(`Sync failed after ${duration}ms`, error)
        SYNC_IN_PROGRESS = false
        IsSyncInProgress.set(false)
        return error
      })
      .chain(result => {
        // When all sync tasks have been run we end up here.
        // It can mean all offers were synced successfully,
        // all offers failed to sync, or a partial success happened.
        // Each sync handles it's own errors. In here, all we have to do
        // is to check whether some of the calls failed and show an error.
        const duration = new Date().getTime() - startTime
        const successful = result.filter(s => s.status === 'SUCCESS')
        const failed = result.filter(s => s.status === 'FAILED')
        SYNC_IN_PROGRESS = false
        IsSyncInProgress.set(false)
        if (failed.length === 0) {
          console.info(`Sync ready in ${duration}ms. Synced ${successful.length} offer(s).`)
          return Task.of()
        } else {
          // We can have from 1..n failed error cases.
          // Every time we handle error: dialog is being shown,
          // and bugsnag error is sent. For user this is not that beneficial
          // as she can only see on dialog but at least we get all errors to bugsnag.
          console.info(`Sync ready in ${duration}ms. Synced ${successful.length} offer(s).
              Sync failed for ${failed.length} offer(s).`)
          failed.forEach(e => handleError(e.error!))
          return Task.rejected(new SyncError(failed))
        }
      })
  }
}

// Performs sync if unsaved offers are found
export function sync(callback?: () => any) {
  createSync().fork(
    err => console.log(err),
    _ => (callback ? callback() : _)
  )
}
// Create a sync task
function createSync() {
  return db
    .getUnsavedOffers()
    .chain(offers => {
      if (offers.length > 0) {
        const doSync = getSync(offers)
        return doSync()
      } else {
        // Skip sync as there are no unsaved offers
        return Task.of()
      }
    })
    .rejectedMap(err => {
      console.error(err)
      return err
    })
}

function isValidationError(error: Error) {
  if (error instanceof HttpClientErrorHolder && error.err instanceof AjaxError) {
    return getErrorName(error.err) === 'ValidationError'
  }
  return false
}

// Completes offer and asynchronously starts a sync for it
// This should only be used when new version is created,
// in other cases you should call sync() instead
export function completeOfferAndScheduleSyncForIt(offerToBeSynced: Offer, afterSyncHasStarted: () => void) {
  // First, update current offer state.
  // Under the hood, this call will make asynchronous update to IndexedDB
  CurrentOffer.set({
    ...offerToBeSynced,
    sfTenderVersionActive: false,
    completed: true,
    finalization_started: true,
    modified_client: Moment().format()
  })

  // If the offer validation fails in the backend, we will get a rejection from sync() and revert the completion.
  const revertOfferUpdate = () => {
    CurrentOffer.set({
      ...offerToBeSynced,
      sfTenderVersionActive: false,
      completed: false,
      finalization_started: null,
      modified_client: Moment().format()
    })
  }

  // Now there should be IndexedDB update happening at the same time.
  // We cannot immediately start the sync, because indexedDB update might not be ready.
  // This solution for this problem is a hack, but it was also a hack previously.
  // Previously it was done in CurrentOffer:
  // https://github.com/konecorp/nemo-sales-tool/blob/eb8c7c35470864b507bf9f5a4c484d375f1a7900/client/js/state/current-offer.ts#L39
  const offerReadyForSync = (offer: Offer | undefined) =>
    offer && offer.completed && offer.finalization_started && !isOfferFinalized(offer)

  const handleSyncError = (error: Error) => {
    if (
      error instanceof SyncError &&
      error.failures.some(f => f.opportunityId === offerToBeSynced.opportunityId && isValidationError(f.error!))
    ) {
      revertOfferUpdate()
      // Schedule a sync to the next event loop.
      // This way both client and the server will have same understanding of current "reverted situation"
      setTimeout(() => sync(), 1)
    }
  }

  const syncAttemptWithRetry = (retriesLeft: number) => () => {
    if (retriesLeft === 0) {
      console.info(`Scheduled sync for ${offerToBeSynced.opportunityId} cancelled, no more retries left.`)
      return
    }
    db.getOfferByOpportunityId(offerToBeSynced.opportunityId).fork(
      _ => _,
      dbOffer => {
        if (offerReadyForSync(dbOffer)) {
          createSync().fork(handleSyncError, _ => _)
          afterSyncHasStarted()
        } else {
          // Wait little bit longer when not ready
          setTimeout(syncAttemptWithRetry(retriesLeft - 1), 500)
        }
      }
    )
  }
  // Do first round of checks quickly, because indexedDB update should be fast.
  // If there is no success with 5 retries, then we forget this sync.
  setTimeout(syncAttemptWithRetry(5), 50)
}
