import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'

interface Props {
  titleKey: string
  messageKey: string
  messageVariable?: any
  onPrimary?: () => void
}
class UserNotification extends React.PureComponent<Props, Record<string, never>> {
  render() {
    const { messageVariable, titleKey, messageKey, onPrimary } = this.props
    return (
      <Dialog title={t(titleKey)} showPrimaryButton={true} showCancelButton={false} onPrimary={onPrimary}>
        {t(messageKey, { messageVariable })}
      </Dialog>
    )
  }
}

export const renderUserNotificationDialog = (
  titleKey: string,
  messageKey: string,
  messageVariable?: any,
  onPrimary?: () => void
) => (
  <UserNotification
    titleKey={titleKey}
    messageKey={messageKey}
    messageVariable={messageVariable}
    onPrimary={onPrimary}
  />
)
