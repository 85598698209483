import { t } from 'i18next'
import * as moment from 'moment'
import { Building, Equipment, EquipmentCategory, EquipmentStId, Offer } from '../../types/types'
import { capitalize } from '../../common/common-utils'
import { CurrentOffer } from './state/current-offer'
import { getOffers } from './db/db'
import { SAPOIInProgress } from './state/global'
import * as ui from './ui-thread'

export const shownPerPage = (x: number) => {
  if (x <= 768) {
    return 1
  } else if (x <= 1024) {
    return 2
  }
  // Linear equations where with window size 1280px we get 3 buildings / equipment per page
  // and with 2560px we get 5 buildings / equipment per page.
  return Math.max(1, Math.ceil(0.0015625 * x + 1))
}

export const emptyFunction = () => {
  /*  do nothing  */
}

export const isPaginatedItemVisible = (currentPage: number, itemsPerPage: number, itemIndex: number) => {
  const minVisibleIndex = currentPage * itemsPerPage
  const maxVisibleIndex = minVisibleIndex + itemsPerPage - 1
  return itemIndex >= minVisibleIndex && itemIndex <= maxVisibleIndex
}

export const openFile =
  (filename: string, fileType: string = 'text/xml') =>
  (file: any) => {
    const link = document.createElement('a')
    const blob = new Blob([file], { type: fileType })
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.dispatchEvent(new MouseEvent(`click`))
    return blob
  }

export const equipmentCategories: EquipmentCategory[] = ['elevator', 'escalator', 'door']

export const getEquipmentNameFromOffer = (id: EquipmentStId): string =>
  CurrentOffer.get().buildings.reduce((desc: string, b: Building) => {
    const eq = b.details.equipment.find(e => e.salesToolId === id)
    return eq ? getEquipmentDescription(eq) : desc
  }, '')

export const getEquipmentDescription = (e: Equipment): string =>
  e.inventoryNumber || e.manufacturingSerialnumber || t(`equipment.new${capitalize(e.equipmentCategory)}`)

export const getAppDirection = (): string => {
  const $body = document.querySelector('body')
  return ($body && $body.dir) || 'ltr'
}

export const getTranslateDirection = (): number => (getAppDirection() === 'rtl' ? 1 : -1)

export const checkForSAPOIInprogress = () => {
  getOffers().fork(
    (err: any) => ui.handleError(err),
    (dbOffers: Offer[]) => {
      const OITimesatamps = dbOffers
        .map(o => o.sapOIStartTimestamp)
        .filter(s => s != null && moment(s).isAfter(moment().subtract(25, 'minutes')))
      SAPOIInProgress.set(OITimesatamps.length ? true : false)
    }
  )
}
