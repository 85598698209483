import * as R from 'ramda'
import { none, some } from 'fp-ts/lib/Option'
import { Optional, Lens } from 'monocle-ts'
import { GSMCampaign, GSMCampaignGroup, ValueAddedServicesAddendum, ValueAddedServicesGroup } from './types'
import { EquipmentGroupStId, Selections } from '../../../../types/types'

const groupL = (groupId: EquipmentGroupStId) =>
  new Optional<ValueAddedServicesAddendum, ValueAddedServicesGroup>(
    s => (s.groups[groupId] ? some(s.groups[groupId]) : none),
    a => s => new ValueAddedServicesAddendum(R.assoc(groupId, a, s.groups), s.interactionSelections)
  )

const selectionsL = Lens.fromProp<ValueAddedServicesGroup, 'selections'>('selections')
// TODO: Remove as part of GSM Clean up
const groupGSM = (groupId: EquipmentGroupStId) =>
  new Optional<GSMCampaign, GSMCampaignGroup>(
    s => (s.groups[groupId] ? some(s.groups[groupId]) : none),
    a => s => new GSMCampaign(R.assoc(groupId, a, s.groups), s.interactionSelections)
  )

const selectionsGSM = Lens.fromProp<GSMCampaignGroup, 'selections'>('selections')

export function updateSelections(
  f: (xs: Selections) => Selections,
  groupId: EquipmentGroupStId,
  offering: ValueAddedServicesAddendum
): ValueAddedServicesAddendum {
  return groupL(groupId).compose(selectionsL.asOptional()).modify(f)(offering)
}
// TODO: Remove as part of GSM Clean up
export function updateGSMSelections(
  f: (xs: Selections) => Selections,
  groupId: EquipmentGroupStId,
  offering: GSMCampaign
): GSMCampaign {
  return groupGSM(groupId).compose(selectionsGSM.asOptional()).modify(f)(offering)
}

export function allSelections(
  groups: Record<EquipmentGroupStId, ValueAddedServicesGroup>
): Record<EquipmentGroupStId, Selections[]> {
  return R.map(g => [g.selections], groups)
}

export function selections(groupId: EquipmentGroupStId, offering: ValueAddedServicesAddendum) {
  return groupL(groupId).compose(selectionsL.asOptional()).getOption(offering)
}
