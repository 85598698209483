import * as R from 'ramda'
import { Offer } from '../../../types/types'
import Atom from '../atom'
import * as dialog from '../nemo/dialog'
import { UnsavedOffersCount } from './global'
import { getOffers, updateOffer } from '../db/db'
import { unsavedOffersCount } from '../nemo/offer'

export const CurrentOffer = new Atom<Offer>('CurrentOffer')

// TODO: Find out a better home for this function.
export const mergeOfferChanges = (currentOffer: Offer, updatedOffer: Partial<Offer>): Offer =>
  R.mergeWith(
    (currentOfferSubtree, updatedOfferSubtree) => {
      // Choose current offer subtree when the subtrees are equal, so we retain object identity for
      // faster React rendering.
      const equalTrees = R.equals(currentOfferSubtree, updatedOfferSubtree)
      return equalTrees ? currentOfferSubtree : updatedOfferSubtree
    },
    currentOffer,
    updatedOffer
  )

CurrentOffer.subscribe(offer => {
  // TODO: Should we perhaps debounce saving to IndexedDB?
  updateOffer(offer)
    .chain(() => getOffers().map(unsavedOffersCount))
    .fork(
      (err: Error) =>
        dialog.error({
          name: err.name,
          message: err.message,
          metaData: { cause: err }
        }),
      (count: number) => {
        UnsavedOffersCount.set(count)
      }
    )
})
