import * as R from 'ramda'

export function isNilOrEmpty(x: any): boolean {
  return x == null || R.isEmpty(x)
}

// Like R.toPairs, but creates a separate pair for each element in an array.
// Example: Ru.toPairsFlatten({a: [1,2], b: 3}) => [['a', 1], ['a', 2], ['b', 3]]
/* eslint max-depth: 0 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function toPairsFlatten<T extends Object>(obj: T): Array<[keyof T, T[keyof T]]> {
  const pairs = [] as Array<[keyof T, T[keyof T]]>

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const val = obj[prop]
      if (Array.isArray(val)) {
        for (const v of val) {
          pairs.push([prop, v])
        }
      } else {
        pairs.push([prop, val])
      }
    }
  }

  return pairs
}

export function notNil(x: any): boolean {
  return x != null
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function mapKeys<T extends Object, K extends string>(fn: (key: keyof T) => K, obj: T): Record<K, T[keyof T]> {
  const newObj = {} as Record<K, T[keyof T]>
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      newObj[fn(k)] = obj[k]
    }
  }
  return newObj
}

// A variant of R.assocPath that modifies the original object. Use if assocPath is too slow.
export function assocPathMutate<T, U>(path: Array<string | number>, val: U, obj: T) {
  const pathParts = R.init(path)
  const key = R.last(path)
  let curr: any = obj
  for (const part of pathParts) {
    curr = curr.hasOwnProperty(part) ? curr[part] : (curr[part] = {})
  }
  curr[key] = val
  return obj
}

type Fn1<A, B> = (value: A) => B
interface Pipe<A> {
  <L>(fn1: Fn1<A, L>): L
  <B, L>(fn1: Fn1<A, B>, fn2: Fn1<B, L>): L
  <B, C, L>(fn1: Fn1<A, B>, fn2: Fn1<B, C>, fn3: Fn1<C, L>): L
  <B, C, D, L>(fn1: Fn1<A, B>, fn2: Fn1<B, C>, fn3: Fn1<C, D>, fn4: Fn1<D, L>): L
  <B, C, D, E, L>(fn1: Fn1<A, B>, fn2: Fn1<B, C>, fn3: Fn1<C, D>, fn4: Fn1<D, E>, fn5: Fn1<E, L>): L
}

export function pipe<A>(value: A): Pipe<A> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (...fns: Function[]) => (R as any).pipe(...fns)(value)
}

export function wrapArray<T>(maybeArray: T[] | T): T[] {
  return Array.isArray(maybeArray) ? maybeArray : [maybeArray]
}

/**
 * Like R.map for objects that returns an array instead of another object. Useful to replace a pattern
 * like R.values(obj).map(x => ...) without the intermediate array.
 */
// eslint-disable-next-line  no-shadow
export function mapObject<T, U>(fn: (value: T[keyof T], key: keyof T, index: number) => U, obj: T): U[] {
  const array: U[] = []
  let index = 0

  if (obj == null) {
    return array
  }

  for (const key in obj) {
    const value = obj[key]
    // @ts-ignore
    array.push(fn(value, key, index))
    index++
  }

  return array
}
