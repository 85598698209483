import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'

class OfferVersionOutdatedDialog extends React.Component<Record<string, never>> {
  render() {
    return (
      <Dialog title={t('tabDetector.offerVersionOutdated.title')} showPrimaryButton={true} showCancelButton={false}>
        {t('tabDetector.offerVersionOutdated.desc')}
      </Dialog>
    )
  }
}

export const renderOfferVersionOutdatedDialog = () => <OfferVersionOutdatedDialog />
