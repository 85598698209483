// NOTE this is used both from ui and worker threads, keep it without dependencies

// with this data we can make notifications to Bugsnag
// see Bugsnag's NotifiableError and INotifyOpts

export interface Notification extends Error {
  name: string
  message: string
  metaData?: object
}

// We cannot serialize objects that include 1. functions or 2. loops.
// The solution for this is to remove the loops and stringify the unknown object.
function stringify(input: any) {
  if (input == null) {
    return undefined
  }
  const replaceCirculars = () => {
    const seen = new WeakSet()
    return (_: string, value: any) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return
        }
        seen.add(value)
      }
      return value
    }
  }
  return JSON.stringify(input, replaceCirculars)
}

export const isNotification = (x?: any): x is Notification => x && x.name && x.message && x.metaData

const safeName = (x: any): string | undefined => x && stringify(x.name)
const safeMessage = (x: any): string | undefined => x && stringify(x.message)

export const fromErrorEvent = (e: ErrorEvent): Notification => ({
  name: safeName(e.error) || 'unhandled-error',
  message: `unhandled-error: ${safeMessage(e.error)}`,
  metaData: {
    lineno: e.lineno,
    colno: e.colno,
    filename: e.filename,
    cause: stringify(e.error) || 'unhandled-error'
  }
})

export const fromRejectionEvent = (e: PromiseRejectionEvent): Notification => ({
  name: safeName(e.reason) || 'unhandled-rejection',
  message: `unhandled-rejection: ${safeMessage(e.reason)}`,
  metaData: {
    cause: stringify(e.reason) || 'unhandled-rejection'
  }
})

export const fromError = (e: Error): Notification => {
  if (isNotification(e)) {
    return e
  }

  return {
    name: e.name,
    message: e.message,
    metaData: {
      cause: e
    }
  }
}
