import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'

interface Props {
  onPrimary: () => void
  onCancel: () => void
}

export default class OfferLockedVerifyNewVersionCreateDialog extends React.Component<Props, Record<string, never>> {
  render() {
    return (
      <Dialog
        title={t('offerWizard.summary.confirm')}
        showCancelButton={true}
        showPrimaryButton={true}
        onPrimary={this.props.onPrimary}
        onCancel={this.props.onCancel}
      >
        {t('offerWizard.summary.lockedOffer')}
      </Dialog>
    )
  }
}
