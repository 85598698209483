/* eslint-disable no-empty */
import Atom from '../atom/index'
import { updateConfig } from '../db/db'
import { emptyFunction } from '../utils'
import { EquipmentGroup, SyncStatus } from '../../../types/types'

export type WizardScope = 'offer' | 'opportunity-selection' | 'assess-grouped' | 'contract' | 'review' | 'info'

export const CurrentScope = new Atom<WizardScope>('CurrentScope', 'opportunity-selection')
export const IsOnline = new Atom<boolean>('IsOnline', false)
export const IsUpgradeOn = new Atom<boolean>('IsUpgradeOn', false)
export const IsSyncInProgress = new Atom<boolean>('IsSyncInProgress', false)
export const IsWizardActive = new Atom<boolean>('IsWizardActive', false)
export const PageTitle = new Atom<string>('PageTitle', '')
export const SyncTimestamp = new Atom<string>('SyncTimestamp')
export const CurrentSync = new Atom<Record<string, SyncStatus>>('currentSync')
export const AutoFetchOpportunities = new Atom<boolean>('AutoFetchOpportunities', true)
export const SAPOIInProgress = new Atom<boolean>('SAPOIInProgress', false)
export const DxGroup = new Atom<Record<string, EquipmentGroup>>('DxGroup')

SyncTimestamp.subscribe(syncTimestamp =>
  updateConfig('syncTimestamp', { syncTimestamp }).fork(
    _ => emptyFunction(),
    _ => emptyFunction()
  )
)
export const UnsavedOffersCount = new Atom<number>('UnsavedOffersCount', 0)

export const DatePickerContext = new Atom<Record<string, React.Component>>('datePickerContext', {})
