/* eslint-disable no-empty */
import * as R from 'ramda'
import convertToEquipmentGroupOffer from '../migrations/convert-to-equipment-group-offer'
import { getUUID } from '../../../common/common-utils'
import { Offer, OfferPreviousVersion, Selections } from '../../../types/types'
import { Dexie } from 'dexie'

const convertIntToUuid = (oldId: number): string => {
  const idString = oldId.toString(16).padStart(32, '0')
  return (
    idString.substr(0, 8) +
    '-' +
    idString.substr(8, 4) +
    '-' +
    idString.substr(12, 4) +
    '-' +
    idString.substr(16, 4) +
    '-' +
    idString.substr(20, 12)
  )
}

export const updateDb = (db: Dexie) => {
  // Dont touch the versions, if you need to change the schema, add a new version
  db.version(1).stores({ offers: '++clientId,opportunityId,active' })
  db.version(2).stores({ offers: '++clientId,opportunityId' })
  db.version(3).stores({ offers: '++clientId,opportunityId,salesOrganization' })
  db.version(4).stores({ offers: '++clientId,opportunityId,salesOrganization', accounts: '&id' })
  db.version(5).stores({ offers: '++clientId,opportunityId,salesOrganization,salesToolId', accounts: '&id' })
  db.version(6).stores({ offers: '++clientId,opportunityId,salesOrganization,salesToolId', accounts: '&id' })
  db.version(7).stores({ offers: '++clientId,opportunityId,salesOrganization,salesToolId', accounts: '&id' })
  db.version(8).stores({ offers: '++clientId,opportunityId,salesOrganization,salesToolId', accounts: '&id' })
  db.version(9).stores({ offers: '++clientId,opportunityId,salesOrganization,salesToolId', accounts: '&id' })
  db.version(10).stores({ offers: '++clientId,&opportunityId,salesOrganization,salesToolId', accounts: '&id' })
  db.version(11).stores({ offers: '++clientId,&opportunityId,salesOrganization,salesToolId', accounts: '&id' })
  db.version(12).stores({
    offers: '++clientId,&opportunityId,salesOrganization,salesToolId',
    accounts: '&id',
    config: '&id'
  })
  db.version(13)
  db.version(14)
  db.version(15).stores({
    offers: '++clientId,&opportunityId,salesOrganization,salesToolId,userId',
    accounts: '&id',
    config: '&id'
  })
  db.version(16)
  db.version(17)
  db.version(18)
  db.version(19)
  db.version(20)
  db.version(21) // Delete offer.sapContractFilename and add offer.sapContractFilenames (string array)
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: any) => {
        const filename = R.path(['sapContractFilename'], offer)
        if (filename !== undefined) {
          delete offer.sapContractFilename
          offer.sapContractFilenames = filename !== null ? new Array(filename) : []
        }
      })
    )

  db.version(22) // Add equipmentCategory and equipmentType for all equipments
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: Offer) => {
        const buildings = offer.buildings
        if (buildings) {
          buildings.forEach(b => {
            if (b.details && b.details.equipment) {
              b.details.equipment.forEach((e: any) => {
                if (!e.equipmentCategory) {
                  e.equipmentCategory = 'elevator'
                }
                if (!e.equipmentType) {
                  e.equipmentType = 'elevator'
                }
              })
            }
          })
        }
      })
    )

  db.version(23) // Remove KCSM_NBR_CO_MONTH
    .upgrade((tx: any) => {
      const migrate = (selections: Selections) => {
        if (selections == null || typeof selections !== 'object') {
          return
        }
        delete selections.KCSM_NBR_CO_MONTH
      }
      return tx.offers.toCollection().modify((offer: Offer) => {
        for (const building of offer.buildings as any) {
          migrate(building.normalLifts.selections)
          migrate(building.criticalLifts.selections)
        }
        migrate(offer.interaction)
      })
    })

  db.version(24).upgrade((tx: any) =>
    tx.offers.toCollection().modify((offer: Offer) => {
      if (!offer.equipmentGroups) {
        convertToEquipmentGroupOffer(offer)
      }
    })
  )

  // Undo what `setOfferEdited` did for finalized offers.
  db.version(25).upgrade((tx: any) =>
    tx.offers.toCollection().modify((offer: any) => {
      if (offer.finalized && !offer.completed) {
        offer.completed = true
        offer.modified_client = offer.modified_server
      }
    })
  )

  db.version(26) // modify KCO_RUSH_CONTRACT_TYPE selection to KCSM_KCC_CONTRACT_TYPE
    .upgrade((tx: any) => {
      const migrate = (selections: any) => {
        if (selections == null || typeof selections !== 'object' || !R.has('KCO_RUSH_CONTRACT_TYPE', selections)) {
          return
        }
        selections.KCSM_KCC_CONTRACT_TYPE = selections.KCO_RUSH_CONTRACT_TYPE
        delete selections.KCO_RUSH_CONTRACT_TYPE
      }
      return tx.offers.toCollection().modify((offer: Offer) => {
        for (const equipmentGroupId in offer.equipmentGroups) {
          const equipmentGroup = offer.equipmentGroups[equipmentGroupId]
          if (equipmentGroup.elevator) {
            if (equipmentGroup.elevator.normal) {
              migrate(equipmentGroup.elevator.normal.selections)
            } else if (equipmentGroup.elevator.critical) {
              migrate(equipmentGroup.elevator.critical.selections)
            }
          } else if (equipmentGroup.escalator) {
            if (equipmentGroup.escalator.normal) {
              migrate(equipmentGroup.escalator.normal.selections)
            } else if (equipmentGroup.escalator.critical) {
              migrate(equipmentGroup.escalator.critical.selections)
            }
          }
        }
        migrate(offer.interaction)
      })
    })

  db.version(27) // offer id for ongoing unsaved offers
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: Offer, ref: { value: any }) => {
        ref.value = R.over(R.lensProp('id'), id => (R.isNil(id) ? getUUID() : convertIntToUuid(id)), offer)
      })
    )

  db.version(28) // default assessment method to standard for existing offers
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: any) => {
        if (offer.assessmentMethod) {
        } else {
          offer.assessmentMethod = 'standard'
        }
      })
    )

  db.version(29).upgrade((tx: any) =>
    // return db.transaction('rw', db.offers, () => {
    tx.offers
      .toCollection()
      .and((o: Offer) => o.opportunity == null)
      .delete()
      .then((count: number) => {
        if (count > 0) {
          console.info(`Deleted ${count} offer(s) that have no opportunities`)
        }
      })
  )

  db.version(30) // modify KCSM_DATA_L_SERVICE selection to KCSM_24_7_CONNECT
    .upgrade((tx: any) => {
      const migrate = (selections: any) => {
        if (selections == null || typeof selections !== 'object' || !R.has('KCSM_DATA_L_SERVICE', selections)) {
          return
        }
        selections.KCSM_24_7_CONNECT = selections.KCSM_DATA_L_SERVICE
        delete selections.KCSM_DATA_L_SERVICE
      }
      return tx.offers.toCollection().modify((offer: Offer) => {
        for (const equipmentGroupId in offer.equipmentGroups) {
          const equipmentGroup = offer.equipmentGroups[equipmentGroupId]
          if (equipmentGroup.elevator) {
            if (equipmentGroup.elevator.normal) {
              migrate(equipmentGroup.elevator.normal.selections)
            } else if (equipmentGroup.elevator.critical) {
              migrate(equipmentGroup.elevator.critical.selections)
            }
          } else if (equipmentGroup.escalator) {
            if (equipmentGroup.escalator.normal) {
              migrate(equipmentGroup.escalator.normal.selections)
            } else if (equipmentGroup.escalator.critical) {
              migrate(equipmentGroup.escalator.critical.selections)
            }
          }
        }
        migrate(offer.interaction)
      })
    })

  db.version(31) // separate contract filenames for gendoc and docgen
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: Offer, ref: { value: any }) => {
        if (offer.contractFilenames && offer.contractFilenames.docgen && offer.contractFilenames.gendoc) {
        } else {
          if (offer.salesOrganization === 'KCC') {
            ref.value = R.assoc(
              'contractFilenames',
              {
                gendoc: {},
                docgen: offer.contractFilenames || {}
              },
              offer
            )
          } else {
            ref.value = R.assoc(
              'contractFilenames',
              {
                gendoc: offer.contractFilenames || {},
                docgen: {}
              },
              offer
            )
          }
        }
      })
    )

  db.version(32) // Migrate old contract to contract list and remove old value
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: any, ref: { value: any }) => {
        if (offer.opportunity && offer.opportunity.contracts) {
        } else {
          ref.value = R.dissocPath(
            ['opportunity', 'contract'],
            R.assocPath(
              ['opportunity', 'contracts'],
              offer.opportunity.contract ? [offer.opportunity.contract] : [],
              offer
            )
          )
        }
      })
    )

  db.version(33) // Change KCSM_24_7_CONNECT from array to single value
    .upgrade((tx: any) => {
      const migrate = (selections: Selections) => {
        if (selections == null || typeof selections !== 'object' || !Array.isArray(selections.KCSM_24_7_CONNECT)) {
          return
        }
        // Take the last value from the array as current selections
        selections.KCSM_24_7_CONNECT = selections.KCSM_24_7_CONNECT[selections.KCSM_24_7_CONNECT.length - 1] || '0'
      }
      return tx.offers.toCollection().modify((offer: Offer) => {
        for (const equipmentGroupId in offer.equipmentGroups) {
          const equipmentGroup = offer.equipmentGroups[equipmentGroupId]
          if (equipmentGroup.elevator) {
            if (equipmentGroup.elevator.normal) {
              migrate(equipmentGroup.elevator.normal.selections)
            } else if (equipmentGroup.elevator.critical) {
              migrate(equipmentGroup.elevator.critical.selections)
            }
          } else if (equipmentGroup.escalator) {
            if (equipmentGroup.escalator.normal) {
              migrate(equipmentGroup.escalator.normal.selections)
            } else if (equipmentGroup.escalator.critical) {
              migrate(equipmentGroup.escalator.critical.selections)
            }
          }
        }
      })
    })

  db.version(34).upgrade((tx: any) => {
    const migrate = (selections: any) => {
      selections.KCSM_INSP_SCE_TEST = ['0']
    }
    return tx.offers.toCollection().modify((offer: Offer) => {
      for (const equipmentGroupId in offer.equipmentGroups) {
        const equipmentGroup = offer.equipmentGroups[equipmentGroupId]
        if (equipmentGroup.elevator) {
          if (equipmentGroup.elevator.normal) {
            migrate(equipmentGroup.elevator.normal.selections)
          } else if (equipmentGroup.elevator.critical) {
            migrate(equipmentGroup.elevator.critical.selections)
          }
        } else if (equipmentGroup.escalator) {
          if (equipmentGroup.escalator.normal) {
            migrate(equipmentGroup.escalator.normal.selections)
          } else if (equipmentGroup.escalator.critical) {
            migrate(equipmentGroup.escalator.critical.selections)
          }
        }
      }
    })
  })

  db.version(35) // group contract filenames based on document templates
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: Offer, ref: { value: any }) => {
        if (
          offer.contractFilenames &&
          offer.contractFilenames.docgen.default &&
          offer.contractFilenames.gendoc['contract-document']
        ) {
        } else {
          const updateContractFilenames = (o: Offer | OfferPreviousVersion) => ({
            docgen: { default: { ...o.contractFilenames.docgen } },
            gendoc: { 'contract-document': { ...o.contractFilenames.gendoc } }
          })
          const updatedPreviousVersions = offer.previousVersions.map(ov =>
            R.pipe(
              (o: OfferPreviousVersion) => R.assoc('contractFilenames', updateContractFilenames(o), o),
              (o: OfferPreviousVersion) => R.assoc('assessmentMethod', o.assessmentMethod, o)
            )(ov)
          )
          ref.value = R.pipe(
            (o: Offer) => R.assoc('contractFilenames', updateContractFilenames(o), o),
            (o: Offer) => R.assoc('previousVersions', updatedPreviousVersions, o)
          )(offer)
        }
      })
    )

  db.version(36) // move partnerAccountNumber to accountNumber
    .upgrade((tx: any) =>
      tx.offers.toCollection().modify((offer: Offer) => {
        offer.contractParties.forEach((party: any) => {
          if (party.partnerAccountNumber && !party.accountNumber) {
            party.accountNumber = party.partnerAccountNumber
            delete party.partnerAccountNumber
          }
        })
        if (offer.partner && (offer.partner as any).partnerAccountNumber && !offer.partner.accountNumber) {
          ;(offer.partner as any).accountNumber = (offer.partner as any).partnerAccountNumber
          delete (offer.partner as any).partnerAccountNumber
        }
      })
    )

  db.version(37).upgrade((tx: any) => {
    const migrate = (selections: any) => {
      if (selections.KCSM_INSP_SCE_COST_COVERAGE != null && Array.isArray(selections.KCSM_INSP_SCE_COST_COVERAGE)) {
        selections.KCSM_INSP_SCE_COST_COVERAGE = R.last(selections.KCSM_INSP_SCE_COST_COVERAGE)
      }
    }
    return tx.offers.toCollection().modify((offer: Offer) => {
      if (offer.equipmentGroups != null) {
        for (const equipmentGroupId in offer.equipmentGroups) {
          const equipmentGroup = offer.equipmentGroups[equipmentGroupId]
          if (equipmentGroup.elevator) {
            if (equipmentGroup.elevator.normal) {
              migrate(equipmentGroup.elevator.normal.selections)
            }
            if (equipmentGroup.elevator.critical) {
              migrate(equipmentGroup.elevator.critical.selections)
            }
          }
          if (equipmentGroup.escalator) {
            if (equipmentGroup.escalator.normal) {
              migrate(equipmentGroup.escalator.normal.selections)
            }
            if (equipmentGroup.escalator.critical) {
              migrate(equipmentGroup.escalator.critical.selections)
            }
          }
          if (equipmentGroup.door) {
            if (equipmentGroup.door.normal) {
              migrate(equipmentGroup.door.normal.selections)
            }
            if (equipmentGroup.door.critical) {
              migrate(equipmentGroup.door.critical.selections)
            }
          }
        }
      }
    })
  })

  db.version(38).upgrade((tx: any) =>
    tx.offers.toCollection().modify((offer: Offer) => {
      if (!offer.opportunityShares) {
        console.info(`Found missing opportunityShares for ${offer.id}. Adding empty array`)
        // Use ts-ignore "readonly" as this is better than typing offer: any
        // @ts-ignore
        offer.opportunityShares = []
      }
    })
  )
}
