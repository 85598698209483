import * as R from 'ramda'
import * as moment from 'moment'
import * as CommercialTermsValidation from '../validation/commercial-terms-validation'
import { filterEquipmentBy } from '../../../common/common-utils'
import { hasContracts } from '../../../common/opportunity'
import { isOfferUnsaved } from '../../../common/offer-state-predicates'
import {
  Building,
  EquipmentCategory,
  Equipment,
  Offer,
  CommercialTerms,
  Opportunity,
  ClientProfile,
  EquipmentGroupStId,
  EquipmentGroups,
  ClientFrontlineConfig,
  PricingModel,
  PricingLevelOptions,
  PricingScope,
  PricingOptions,
  Elevator,
  BundleSelection,
  BundleGroupSelection,
  BundleService,
  BundleGroup,
  Bundle,
  OfferSelections,
  PricingConfigJson
} from '../../../types/types'
import { CommercialTermRow } from '../../../backend/db/tables'
import { equipmentCategories } from '../utils'
import {
  getEscalationEquipmentStartDate,
  getEscalationStartDate,
  isKcsmEscalationDateEnabled
} from '../components/OfferWizard/CommercialTerms/CommercialTermsUtils'
import { CommercialTermKey, EquipmentDate } from 'nemo-pricing'
import { getFlattenedItems } from '../../../common/common-utils'
import { PricingConfig } from 'common/pricing/price-breakdown-types'
import { preparePricingConfig } from '../initialization/config-prepare'
import { compileOfferingQuestions } from './offering/questions'
import { QuestionState, InteractionUiState } from '../components/OfferWizard/Offering/model'
import { dcsServicesForGrpTag, dxKoneConnectivityDeviceValue } from '../../../common/equipment-utils'

// ------------------------------------
// Lenses
// ------------------------------------

export const getElevatorCountForOffer = (buildings: Building[]) =>
  buildings.map(getBuildingElevatorCount).reduce(R.add, 0)

export const getEscalatorCountForOffer = (buildings: Building[]) =>
  buildings.map(getBuildingEscalatorCount).reduce(R.add, 0)

export const getDoorCountForOffer = (buildings: Building[]) => buildings.map(getBuildingDoorCount).reduce(R.add, 0)

export const getPeopleCountForOffer = (buildings: Building[]) =>
  buildings.map(getBuildingResiflowCount).reduce(R.add, 0)

export const getBuildingCountForOffer = (buildings: Building[]) => R.length(buildings) || 0

export const getBuildingEquipmentCount = (building: Building) => R.pathOr([], ['details', 'equipment'], building).length

const getInteractionEquipmentCount = (building: Building) =>
  getBuildingEquipmentCount(building) - getBuildingResiflowCount(building)

export const getEquipmentCountForOffer = (buildings: Building[]) =>
  buildings.map(getInteractionEquipmentCount).reduce(R.add, 0)

const getBuildingElevatorCount = (building: Building) => getEquipmentCountByEquipmentCategory(building, 'elevator')

const getBuildingEscalatorCount = (building: Building) => getEquipmentCountByEquipmentCategory(building, 'escalator')

const getBuildingDoorCount = (building: Building) => getEquipmentCountByEquipmentCategory(building, 'door')

const getBuildingResiflowCount = (building: Building) => getEquipmentCountByEquipmentCategory(building, 'resiflow')

const getEquipmentCountByEquipmentCategory = (building: Building, equipmentCategory: EquipmentCategory) => {
  const equipment = R.pathOr([], ['details', 'equipment'], building)
  return filterEquipmentBy(equipmentCategory)(equipment).length
}

export const vasAddendumHasNoEscalators = (offer: Offer): boolean =>
  offer.opportunity.opportunityCategory !== 'Value Added Service' || getEscalatorCountForOffer(offer.buildings) === 0

export const vasAddendumHasNoResiflow = (offer: Offer): boolean =>
  offer.opportunity.opportunityCategory !== 'Value Added Service' || getPeopleCountForOffer(offer.buildings) === 0

export const unsavedOffersCount = (offers: Offer[]) => R.pipe(R.filter(isOfferUnsaved), R.length)(offers)

export function setOfferEdited(offer: Offer) {
  return R.merge(offer, {
    modified_client: moment.utc().format(),
    completed: false
  })
}

export const getCommercialTermsErrors = ({
  commercialTermsConfig,
  selectedCommercialTerms,
  isContractStartDateAtEquipmentLevel,
  isBillingModeApplied
}: {
  commercialTermsConfig: CommercialTermRow[]
  selectedCommercialTerms: CommercialTerms
  isContractStartDateAtEquipmentLevel: boolean
  isBillingModeApplied: boolean
}): PRecord<CommercialTermKey, never[]> =>
  commercialTermsConfig.reduce(
    (acc, commercialTerm) =>
      CommercialTermsValidation.validateCommercialTerm(
        selectedCommercialTerms,
        commercialTerm,
        isContractStartDateAtEquipmentLevel,
        isBillingModeApplied
      )
        ? acc
        : R.assoc(commercialTerm.key, [], acc), // An empty list for now. Figure out the exact API later on.
    {}
  )

const isRenegotiation = (opportunity: Opportunity) =>
  opportunity.opportunityCategory !== 'Value Added Service' && hasContracts(opportunity)

export const shouldUseRenegotiationPricing = (
  opportunity: Opportunity,
  profile: ClientProfile,
  pricingModel: PricingModel
) =>
  profile.canUseRenegotiationPricing &&
  isRenegotiation(opportunity) &&
  (pricingModel === 'optionA' || pricingModel === 'optionAC')

export const isQuickTender = (offer: Offer) => R.not(isFlexible(offer))

export const isFlexible = (offer: Offer) => R.pipe(R.path(['quickTenderScope']), R.equals('flexible'))(offer)

export const offerHasSapContract = (offer: Offer) => R.has('sapContractNumber', offer)

/* copies all selection values
 * from:
 * offer.equipmentGroups.{equipmentCategory}.{criticality}.selections
 * to:
 * offer.initialData.{equipmentCategory}.{criticality} */
export const setInitialBuildingSelections = (offer: Offer) => {
  const getGroupSelectionsFor = (lensPath: R.Path, group: EquipmentGroupStId) => {
    const lens = R.lensPath(lensPath)
    return R.view(lens, group)
  }

  const copySelections = (lensPath: R.Path) => (group: EquipmentGroupStId) => {
    // selections are available under 'selections' key
    const groupSelections = getGroupSelectionsFor(lensPath.concat('selections'), group)
    if (groupSelections) {
      return R.assocPath(lensPath, groupSelections, group)
    } else {
      return R.dissocPath(lensPath, group)
    }
  }

  const selections = R.mapObjIndexed(
    (group, _) =>
      R.pipe(
        R.pick<EquipmentGroups, EquipmentGroupStId>(equipmentCategories) as any, // Discard all other fields
        copySelections(['elevator', 'normal']),
        copySelections(['elevator', 'critical']),
        copySelections(['escalator', 'normal']),
        copySelections(['escalator', 'critical'])
      )(group),
    offer.equipmentGroups
  )

  return R.assocPath(['initialData', 'selections'], selections, offer)
}

const setInitialInteractionSelections = (overwrite: boolean) => (offer: Offer) => {
  const initialInteraction = R.path(['initialData', 'interaction'], offer)
  const updatedInitialInteraction = overwrite ? offer.interaction : initialInteraction || offer.interaction
  return R.assocPath(['initialData', 'interaction'], updatedInitialInteraction, offer)
}

const setInitialPrices = (overwrite: boolean) => (offer: Offer) => {
  const initialPrices = (offer.initialData ? offer.initialData.prices : undefined) || {}
  const updatedInitialPrices = offer.prices.reduce((acc, price) => {
    const { id } = price
    const initialPrice = overwrite ? price.discountedPrice : R.propOr(price.price || 0, id, initialPrices)
    return R.assoc(id, initialPrice, acc)
  }, {})
  return R.assocPath(['initialData', 'prices'], updatedInitialPrices, offer)
}

export const setInitialWarrantyOptions = (frontlineConfig: ClientFrontlineConfig) => (offer: Offer) => {
  const { warrantyOptions } = frontlineConfig
  if (warrantyOptions == null || warrantyOptions.length === 0) {
    return R.dissoc('warranty', offer)
  }
  const { warranty } = offer
  if (warranty != null) {
    return offer
  }
  return R.assoc('warranty', 0, offer)
}

export const setDefaultEscalationDate = (frontlineConfig: ClientFrontlineConfig) => (offer: Offer) => {
  const { commercialTerms } = offer
  const { VBEGDAT: contractStartDate, KCSM_BASE_MONTH_ESCALATION: currentEscalationDate } = commercialTerms
  if (!contractStartDate) {
    return offer
  }
  if (currentEscalationDate && isKcsmEscalationDateEnabled(frontlineConfig)) {
    return offer
  }
  const escalationDate = offer.isContractStartDateAtEquipmentLevel
    ? getEscalationEquipmentStartDate(frontlineConfig, contractStartDate as EquipmentDate, offer)
    : getEscalationStartDate(frontlineConfig, contractStartDate as string)
  return R.assocPath(['commercialTerms', 'KCSM_BASE_MONTH_ESCALATION'], escalationDate, offer)
}

export const setInitialData = (overwrite: boolean, frontlineConfig: ClientFrontlineConfig) => (offer: Offer) =>
  R.pipe(
    setInitialBuildingSelections,
    setInitialInteractionSelections(overwrite),
    setInitialPrices(overwrite),
    setInitialWarrantyOptions(frontlineConfig)
  )(offer)

export const getEquipmentForGroup = (groupId: EquipmentGroupStId, buildings: Building[] | null) =>
  R.pipe(
    R.chain(R.path(['details', 'equipment'])),
    R.filter((e: Equipment) => e.groupId === groupId)
  )(buildings || [])

export const getEquipmentByGroup = (offer: Offer) =>
  R.groupBy(eq => eq.groupId, getFlattenedItems(R.map(building => building.details.equipment, offer.buildings)))

export const isMarketSegmentMissing = (opportunity: Opportunity) => !opportunity.marketSegment

export const isFundingSectorMissing = (opportunity: Opportunity) => !opportunity.fundingSector

export const getInteractionVarsForOffer = (
  pricingOptions: ReadonlyArray<string>,
  interactionVars: any,
  equipmentCountForOffer: number
) => {
  const getVarForOption = (o: string) => {
    const interactionVarForOption = interactionVars[o] as [
      string,
      { mv_type: 'amount' | 'percentage'; value: number } | number
    ][]
    return interactionVarForOption.map(([interactionOption, priceEffect]) => [
      interactionOption,
      typeof priceEffect === 'number'
        ? priceEffect / equipmentCountForOffer
        : {
            ...priceEffect,
            value: priceEffect.value / equipmentCountForOffer
          }
    ])
  }
  return pricingOptions.map(o => ({ [o]: getVarForOption(o) })).reduce((acc, curVal) => ({ ...acc, ...curVal }), {})
}

export const updatePricingConfig =
  (pricingConfig: PricingConfig) =>
  (frontlineConfig: ClientFrontlineConfig): ClientFrontlineConfig => ({
    ...frontlineConfig,
    pricingConfig
  })

export const getPricingVariablesForOffer = (offerSelections: OfferSelections, config: PricingConfigJson) => {
  const offeringQuestions = compileOfferingQuestions(offerSelections)
  return preparePricingConfig(offeringQuestions, config, true)
}

export const contractInteractionOptions = <T extends string>(
  priceLevelOption: PricingLevelOptions<T>
): ReadonlyArray<string> =>
  (Object.values(priceLevelOption) as Record<'contract' | 'equipment', ReadonlyArray<string>>[])
    .map(v => v.contract)
    .reduce((acc, curVal) => acc.concat(curVal), [])

export function optionsForOfferScope(scope: PricingScope | null, options: PricingOptions) {
  switch (scope) {
    case 'flexible':
    case 'kone_care_taker':
      return options.interaction[scope]
    case 'konexion':
      return options.additional_services.konexion
    case 'value_added_services_addendum':
      return options.value_added_services_addendum
    case 'gsm_campaign': // TODO: Remove as part of GSM Clean up
      return options.gsm_campaign
    default:
      return options.interaction.flexible
  }
}

export const equipmentIsElevator = (e: Equipment): boolean => e.equipmentCategory === 'elevator'
export const isDxElevator = (e: Elevator) =>
  (e.koneConnectivityDeviceType != null &&
    e.koneConnectivityDeviceType.length &&
    e.koneConnectivityDeviceType.toLowerCase() !== dxKoneConnectivityDeviceValue) ||
  (e.apfComponent != null && e.apfComponent.length !== 0)
export const isDXEquipment = (e: Elevator) =>
  e.koneConnectivityDeviceType && e.koneConnectivityDeviceType.toLowerCase().includes(dxKoneConnectivityDeviceValue)
    ? true
    : false
export const isDCSEquipment = (e: Elevator) =>
  e.apfComponent && e.apfComponent.split(',').some(c => dcsServicesForGrpTag.includes(c.toLowerCase()))

export const anyDxElevators = (buildings: Building[]) =>
  R.pipe(
    R.chain<Building, Equipment>(b => b.details.equipment),
    R.filter<Elevator>(equipmentIsElevator),
    R.any(isDxElevator)
  )(buildings)

export const dissocBundleGroupSelection = (offer: Offer, path: string[][] | null) =>
  path
    ? path.reduce((acc, currentPath) => R.dissocPath(['bundleGroupSelection', ...currentPath], acc), offer)
    : R.assocPath(['bundleGroupSelection'], {}, offer)

export const hasBundleServices = (
  questions: Pick<QuestionState, 'key'>[],
  appliedBundles: Record<string, BundleSelection>
) => {
  const totalServiceList = R.reduce(
    (acc: BundleService[], currentBundle: BundleSelection) => acc.concat(currentBundle.serviceList),
    [],
    R.values(appliedBundles)
  )
  const services = totalServiceList.map(serv => serv.label) as string[]
  return questionsHasService(questions, services)
}

export const hasBundleInteractionServices = (questions: Pick<InteractionUiState, 'key'>[], services: string[]) =>
  questionsHasService(questions, services)

const questionsHasService = (questions: Pick<QuestionState | InteractionUiState, 'key'>[], services: string[]) =>
  questions.map(quest => quest.key).some(key => services.includes(key))

export const getSelectedBundle = (
  offerBundleGroupSelection: BundleGroupSelection | undefined,
  groupId: EquipmentGroupStId,
  category: string
): string | null => {
  const selectedKeys = Object.keys(R.pathOr({}, [groupId, category], offerBundleGroupSelection))
  return selectedKeys[0] || null
}

export const hasNoPackagesInElevator = (offer: Offer) =>
  !offer.bundleGroupSelection ||
  R.isEmpty(offer.bundleGroupSelection) ||
  R.values(offer.bundleGroupSelection).every(groupData => R.isEmpty(R.pathOr({}, ['elevator'], groupData)))

export const bundleHasCoreService = (eqType: EquipmentCategory, groupBundles: BundleGroup | undefined) =>
  R.values(R.pathOr({}, [eqType], groupBundles)).some(
    (bundle: Bundle) => bundle.type === 'core_vas' || bundle.type === 'core'
  )
