import * as R from 'ramda'
import { SapKey, Selection, Selections } from '../../../../types/types'

const and = (...args: any[]) => R.all(Boolean, args)
const nand = R.complement(and)
const or = (...args: any[]) => R.any(Boolean, args)

export function setContractType(selections: Selections) {
  const emptyList = ['0']
  const legacyEmptyList: Selection = []

  const get = (key: SapKey) => selections[key]
  const set = (key: SapKey, val: Selection) => R.assoc(key, val, selections)
  const eq = (key: SapKey, val: Selection) => R.equals(get(key), val)
  const neq = R.complement(eq)
  const isNil = (key: SapKey) => get(key) == null
  const notNil = R.complement(isNil)
  const eqOrNil = (key: SapKey, val: Selection) => eq(key, val) || R.isNil(get(key))
  const neitherEqNorNil = R.complement(eqOrNil)
  const oneOf = (key: SapKey, vals: Selection) => R.contains(get(key), Array.isArray(vals) ? vals : [])
  const notOneOf = (key: SapKey, vals: Selection) => notNil(key) && !oneOf(key, vals)
  const isEmpty = (key: SapKey) => eqOrNil(key, emptyList) || eq(key, legacyEmptyList)
  const nonEmpty = R.complement(isEmpty)

  const mbmMethod = eq('KCSM_CARE_MBM', '1')
  const hasCalloutThreshold = and(neq('KCSM_CO', '1'), nand(eqOrNil('KCSM_CO', '0'), eqOrNil('KCSM_NBR_CO_YEAR', '0')))
  const allCalloutsIncluded = and(eq('KCSM_CO', '1'), eqOrNil('KCSM_NBR_CO_YEAR', '0'))
  const hasCalloutThresholdOrAllCalloutsIncluded = or(hasCalloutThreshold, allCalloutsIncluded)

  if (R.isNil(selections) || R.isEmpty(selections)) {
    return selections
  }
  if (and(mbmMethod, eqOrNil('KCSM_CO', '0'), eqOrNil('KCSM_NBR_CO_YEAR', '0'))) {
    return set('KCSM_KCC_CONTRACT_TYPE', '11')
  }
  if (
    and(
      mbmMethod,
      hasCalloutThresholdOrAllCalloutsIncluded,
      or(eqOrNil('KCSM_SR', '0'), and(eq('KCSM_SR', 'NA'), eqOrNil('KCSM_SR_LAB', '0'), eqOrNil('KCSM_SR_SPARE', '0'))),
      isEmpty('KCSM_MPG'),
      isEmpty('KCSM_COMPONENT')
    )
  ) {
    return set('KCSM_KCC_CONTRACT_TYPE', '12')
  }
  if (
    and(
      mbmMethod,
      hasCalloutThresholdOrAllCalloutsIncluded,
      or(
        and(eq('KCSM_SR', '1'), eq('KCSM_SR_SPARE', '3'), nonEmpty('KCSM_MPG')),
        and(eq('KCSM_SR', '1'), eq('KCSM_SR_SPARE', '5'), nonEmpty('KCSM_COMPONENT')),
        and(or(eq('KCSM_SR_LAB', '1'), eq('KCSM_SR_LAB', '38')), or(eq('KCSM_SR_SPARE', '3'), eq('KCSM_SR_SPARE', '5')))
      )
    )
  ) {
    return set('KCSM_KCC_CONTRACT_TYPE', '14')
  }
  if (
    and(
      mbmMethod,
      hasCalloutThresholdOrAllCalloutsIncluded,
      or(
        notOneOf('KCSM_SR', ['0', '1', 'NA']),
        and(
          eq('KCSM_SR', 'NA'),
          nand(eq('KCSM_SR_SPARE', '0'), eq('KCSM_SR_LAB', '0')),
          nand(eq('KCSM_SR_SPARE', '1'), eq('KCSM_SR_LAB', '1'))
        ),
        and(eqOrNil('KCSM_SR', '0'), eq('KCSM_SR_SPARE', '2'), nonEmpty('KCSM_MPG')),
        and(eqOrNil('KCSM_SR', '0'), eq('KCSM_SR_SPARE', '4'), nonEmpty('KCSM_COMPONENT'))
      )
    )
  ) {
    return set('KCSM_KCC_CONTRACT_TYPE', '13')
  }

  if (
    and(
      mbmMethod,
      hasCalloutThresholdOrAllCalloutsIncluded,
      or(
        and(eq('KCSM_SR', 'NA'), eq('KCSM_SR_LAB', '1'), or(eq('KCSM_SR_SPARE', '1'), eq('KCSM_SR_SPARE', '38'))),
        and(eq('KCSM_SR', '1'), or(isNil('KCSM_SR_SPARE'), notOneOf('KCSM_SR_SPARE', ['3', '5'])))
      )
    )
  ) {
    return set('KCSM_KCC_CONTRACT_TYPE', '15')
  }
  if (or(neitherEqNorNil('KCSM_APF_ACCESS_300', '0'), neitherEqNorNil('KCSM_APF_VISIT_300', '0'))) {
    return set('KCSM_KCC_CONTRACT_TYPE', '16')
  }
  return set('KCSM_KCC_CONTRACT_TYPE', null)
}
