import * as moment from 'moment'
import { round } from '../../../../common/prices'
import {
  EditableBuildingFields,
  EditableEquipmentFields,
  EscalatorQuestion,
  ElevatorQuestion,
  EquipmentType,
  DoorQuestion,
  EquipmentQuestion,
  DoorType,
  ResiflowQuestion,
  BuildingQuestion,
  ChoiceAsKeyValuePair
} from '../../../../types/types'

export interface Validator<T> {
  /* Transforms a value from an <input> element (always a string) to the domain T. */
  coerce: (value: string | boolean) => T
  /* Checks if a coerced value is a valid value in the domain. */
  validate: (value: T) => boolean
  /* Checks if coerced value is valid value in the paired domain */
  pairValidate?: (p: boolean) => (q: boolean) => boolean
}

type Validations<T> = { [K in keyof T]: Validator<T[K]> }

const string = <S extends string>(value: string): S => value as S
const stringOrNull = <S extends string>(value: string): S | null => (value.length > 0 ? (value as S) : null)

const booleanOrNull = (value: any): boolean | null => (value !== null ? (value as boolean) : null)

const notNull = <T>(value: T | null): boolean => value !== null
const notEmptyString = (value: string | null) => typeof value === 'string' && value.length > 0

const isNumberBetween =
  (lower: number, upper: number) =>
  (num: number): boolean =>
    Number.isFinite(num) && num >= lower && num <= upper

const isIntegerBetween = (lower: number, upper: number) => {
  const isBetween = isNumberBetween(lower, upper)
  return (num: number): boolean => Number.isInteger(num) && isBetween(num)
}

const numberOrNull = (value: string): number | null => {
  if (value.length === 0) {
    return null
  }
  const maybeNumber = round(Number(value))
  return Number.isFinite(maybeNumber) ? maybeNumber : null
}

const alwaysValid = () => true

const booleanPairs = (p: boolean) => (q: boolean) => p || q

const startYear = 1800
const endYear = moment().year() + 5

const hasLength = (limit: number) => (value: any) => value.toString().length <= limit
const validateSpeed = (elevatorQuestion: ElevatorQuestion) => (num: number) =>
  isNumberBetween(
    elevatorQuestion.min != null ? elevatorQuestion.min : 0,
    elevatorQuestion.max != null ? elevatorQuestion.max : 20
  )(num) && hasLength(4)(num)

const isIncludedIn = <T>(arr: T[], value: T) => arr.includes(value)

const buildingValidations: Validations<EditableBuildingFields> = {
  buildingAddress: {
    coerce: stringOrNull,
    validate: notEmptyString
  },
  buildingCity: {
    coerce: stringOrNull,
    validate: notEmptyString
  },
  buildingDescription: {
    coerce: stringOrNull,
    validate: alwaysValid
  },
  buildingName: {
    coerce: stringOrNull,
    validate: alwaysValid
  }
}

export const getBuildingValidation = (
  buildingQuestion: BuildingQuestion
): Validator<string | number | null | boolean> => {
  switch (buildingQuestion.key) {
    case 'buildingAddress':
    case 'buildingCity':
    case 'buildingDescription':
    case 'buildingName':
      return buildingValidations[buildingQuestion.key]
    default:
      return {
        coerce: stringOrNull,
        validate: validateDynamicTechnicalData(buildingQuestion)
      }
  }
}

const commonEquipmentValidations: Validations<EditableEquipmentFields> = {
  koneEquipmentNumber: {
    coerce: stringOrNull,
    validate: alwaysValid
  },
  inventoryNumber: {
    coerce: stringOrNull,
    validate: alwaysValid
  },
  manufacturer: {
    coerce: stringOrNull,
    validate: notNull
  },
  manufacturingSerialnumber: {
    coerce: stringOrNull,
    validate: alwaysValid
  },
  operationalEnvironment: {
    coerce: stringOrNull,
    validate: notNull
  },
  technicalPlatform: {
    coerce: stringOrNull,
    validate: notNull
  },
  year: {
    coerce: numberOrNull,
    validate: isIntegerBetween(startYear, endYear)
  },
  locationName: {
    coerce: stringOrNull,
    validate: notNull
  },
  locationAddress: {
    coerce: stringOrNull,
    validate: notEmptyString
  },
  locationCity: {
    coerce: stringOrNull,
    validate: notEmptyString
  },
  equipmentAddress: {
    coerce: stringOrNull,
    validate: notEmptyString
  }
}

const validateDynamicTechnicalData =
  (equipmentQuestion: EquipmentQuestion | BuildingQuestion) => (x: string | null) => {
    let isValid = !(equipmentQuestion.mandatory && !x)
    if (equipmentQuestion.type === 'select' && Array.isArray(equipmentQuestion.choices) && x) {
      const isSingleType = equipmentQuestion.choices.some((ch: string | ChoiceAsKeyValuePair) => typeof ch === 'string')
      isValid = isSingleType
        ? (equipmentQuestion.choices as string[]).includes(x)
        : (equipmentQuestion.choices as ChoiceAsKeyValuePair[]).some(ch => ch.key === x)
    }
    return isValid
  }

const validateMultiChoiceDynamicTechnicalData =
  (equipmentQuestion: EquipmentQuestion, isAPFFromCRM: boolean) => (x: string | null) => {
    const { type, key, choices, mandatory } = equipmentQuestion
    const choicesSingleType = choices as string[]
    const isValid = !(mandatory && !x)

    if (isValid && type === 'select' && Array.isArray(choicesSingleType) && x) {
      const multiAnswer = x.split(',')
      const isCRMValues = key === 'apfComponent' ? isAPFFromCRM : false
      return isCRMValues || choicesSingleType.some(choice => multiAnswer.includes(choice))
    }

    return isValid
  }

export const getElevatorValidation = (
  elevatorQuestion: ElevatorQuestion,
  frontlineEnabledTechnicalPlatforms: string[],
  isAPFFromCRM: boolean
): Validator<string | number | null | boolean> => {
  switch (elevatorQuestion.key) {
    case 'additionalDoors':
      return {
        coerce: numberOrNull,
        validate: isIntegerBetween(0, 2)
      }
    case 'equipmentType':
      return {
        coerce: string,
        validate: notNull
      }
    case 'ratedSpeed':
      return {
        coerce: numberOrNull,
        validate: validateSpeed(elevatorQuestion)
      }
    case 'doorsCount':
    case 'ratedLoad':
      return {
        coerce: numberOrNull,
        validate: isIntegerBetween(
          elevatorQuestion.min != null ? elevatorQuestion.min : 0,
          elevatorQuestion.max != null ? elevatorQuestion.max : 100000
        )
      }
    case 'technicalPlatform':
      return {
        coerce: stringOrNull,
        validate: (value: string | null) => isIncludedIn(frontlineEnabledTechnicalPlatforms, value)
      }
    case 'controlSystem':
    case 'controllerType':
    case 'technicalId':
    case 'landingDoorType':
    case 'carDoorType':
    case 'driveSystem':
    case 'koneConnectivityDeviceType':
    case 'locationRegion':
    case 'locationCountry':
    case 'locationPostalCode':
      return {
        coerce: stringOrNull,
        validate: validateDynamicTechnicalData(elevatorQuestion)
      }
    // Add all multi choices validation
    case 'apfComponent':
      return {
        coerce: stringOrNull,
        validate: validateMultiChoiceDynamicTechnicalData(elevatorQuestion, isAPFFromCRM)
      }
    default:
      return commonEquipmentValidations[elevatorQuestion.key]
  }
}

export const getResiflowValidation = (
  resiflowQuestion: ResiflowQuestion,
  frontlineEnabledTechnicalPlatforms: string[]
): Validator<string | number | boolean | null> => {
  switch (resiflowQuestion.key) {
    case 'technicalPlatform':
      return {
        coerce: stringOrNull,
        validate: (value: string | null) => isIncludedIn(frontlineEnabledTechnicalPlatforms, value)
      }
    case 'numberOfApartments':
      return {
        coerce: numberOrNull,
        validate: isIntegerBetween(
          resiflowQuestion.min != null ? resiflowQuestion.min : 0,
          resiflowQuestion.max != null ? resiflowQuestion.max : 9999
        )
      }
    case 'technicalId':
      return {
        coerce: stringOrNull,
        validate: validateDynamicTechnicalData(resiflowQuestion)
      }
    case 'intercoms':
    case 'accessReaders':
      return {
        coerce: booleanOrNull,
        validate: () => false,
        pairValidate: booleanPairs
      }
    case 'locationRegion':
    case 'locationCountry':
    case 'locationPostalCode':
      return {
        coerce: stringOrNull,
        validate: notNull
      }
    default:
      return commonEquipmentValidations[resiflowQuestion.key]
  }
}

export const getEscalatorValidation = (
  escalatorQuestion: EscalatorQuestion,
  equipmentType: EquipmentType,
  frontlineEnabledEscalatorTypes: string[]
): Validator<string | number | null> => {
  switch (escalatorQuestion.key) {
    case 'equipmentType':
      return {
        coerce: string,
        validate: notNull
      }
    case 'numberOfDrives':
    case 'operationMode':
    case 'operationalEnvironmentCondition':
    case 'locationRegion':
    case 'locationCountry':
    case 'locationPostalCode':
      return {
        coerce: stringOrNull,
        validate: validateDynamicTechnicalData(escalatorQuestion)
      }
    case 'length':
      return equipmentType === 'autowalk'
        ? {
            coerce: numberOrNull,
            validate: isIntegerBetween(
              escalatorQuestion.min != null ? escalatorQuestion.min : 0,
              escalatorQuestion.max != null ? escalatorQuestion.max : 100000
            )
          }
        : {
            coerce: numberOrNull,
            validate: Number.isInteger
          }
    case 'rise':
      return equipmentType === 'escalator'
        ? {
            coerce: numberOrNull,
            validate: isIntegerBetween(
              escalatorQuestion.min != null ? escalatorQuestion.min : 0,
              escalatorQuestion.max != null ? escalatorQuestion.max : 100000
            )
          }
        : {
            coerce: numberOrNull,
            validate: Number.isInteger
          }
    case 'escalatorType':
      return {
        coerce: stringOrNull,
        validate: (value: string | null) => isIncludedIn(frontlineEnabledEscalatorTypes, value)
      }
    case 'balustradeMaterial':
      return {
        coerce: stringOrNull,
        validate: validateDynamicTechnicalData(escalatorQuestion)
      }
    default:
      return commonEquipmentValidations[escalatorQuestion.key]
  }
}

export const getDoorValidation = (
  doorQuestion: DoorQuestion,
  allowedDoorTypes: DoorType[]
): Validator<string | number | null> => {
  switch (doorQuestion.key) {
    case 'doorUsage':
    case 'locationRegion':
    case 'locationCountry':
    case 'locationPostalCode':
      return {
        coerce: stringOrNull,
        validate: validateDynamicTechnicalData(doorQuestion)
      }
    case 'workingHeight':
      return {
        coerce: numberOrNull,
        validate: isIntegerBetween(doorQuestion.min || 1, doorQuestion.max || 5)
      }
    case 'doorType':
      return {
        coerce: stringOrNull,
        validate: v => allowedDoorTypes.includes(v as any)
      }
    default:
      return commonEquipmentValidations[doorQuestion.key]
  }
}
