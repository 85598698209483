import {
  Criticality,
  EquipmentCategory,
  EquipmentGroupStId,
  FlexibleScope,
  GroupKind,
  InteractionScope,
  QuickTenderScope,
  Selections
} from '../../../../types/types'
export * from '../offering/dto'
export * from '../offering/repair-selections'
export { match } from '../offering/match'
import { DoorGroups, ElevatorEscalatorApfGroups } from './types/flexible'
export { ElevatorsEscalatorsApfs, Doors, DoorGroups, ElevatorEscalatorApfGroups } from './types/flexible'
export type {
  FlexibleModuleScopes,
  FlexibleRatingsAndSelections,
  FlexibleCriticalities,
  FlexibleGroup,
  Groups,
  ModuleScopes
} from './types/flexible'

export interface Interaction {
  scope: InteractionScope
  selections: Selections
}

export type ResiFlowInteraction = null

export class Flexible {
  readonly _tag: 'Flexible' = 'Flexible' as const
  constructor(
    readonly groups: ElevatorEscalatorApfGroups | DoorGroups,
    readonly interaction: Interaction | ResiFlowInteraction
  ) {}
}

// --- Quick tenders

export interface QuickTenderCriticality {
  readonly flexibleRating: FlexibleScope
  readonly selections: Selections
}

// Selections can differ between normal/critical
// equipment in a quick tender offering
export interface QuickTenderEquipmentCategory {
  readonly normal: QuickTenderCriticality | null
  readonly critical: QuickTenderCriticality | null
}

// Note that this can only contain equipment in the same category,
// You cannot mix elevators and escalators for example.
export interface QuickTenderGroupWithRating {
  readonly salesToolId: EquipmentGroupStId
  readonly name: string
  readonly groupKind: GroupKind
  readonly selections: QuickTenderEquipmentCategory
}

export class QuickTenderWithRatings {
  readonly _tag: 'QuickTenderWithRatings' = 'QuickTenderWithRatings' as const
  constructor(
    readonly groups: Record<EquipmentGroupStId, QuickTenderGroupWithRating>,
    readonly scope: QuickTenderScope,
    readonly interactionSelections: Selections,
    readonly selectedInteractionRating: InteractionScope
  ) {}
}

export interface QuickTenderGroup {
  readonly salesToolId: EquipmentGroupStId
  readonly name: string
  readonly groupKind: GroupKind
  readonly selections: Selections
}

export class QuickTender {
  readonly _tag: 'QuickTender' = 'QuickTender' as const
  constructor(
    readonly groups: Record<EquipmentGroupStId, QuickTenderGroup>,
    readonly scope: QuickTenderScope,
    readonly interactionSelections: Selections
  ) {}
}

// --- QuickTenderScopePending

export interface QuickTenderGroupWithoutRatings {
  readonly salesToolId: EquipmentGroupStId
  readonly groupKind: GroupKind
  readonly name: string
}

export class QuickTenderScopePending {
  readonly _tag: 'QuickTenderScopePending' = 'QuickTenderScopePending' as const
  constructor(readonly groups: Record<EquipmentGroupStId, QuickTenderGroupWithoutRatings>) {}
}

// --- NoRatings

export class NoRatings {
  static value = new NoRatings()
  readonly _tag: 'NoRatings' = 'NoRatings' as const
}
export const noRatings = NoRatings.value

// --- ValueAddedServices

export interface ValueAddedServicesGroup {
  readonly salesToolId: EquipmentGroupStId
  readonly name: string
  readonly groupKind: GroupKind
  readonly selections: Selections
}

// TODO: Remove as part of GSM Clean up
export interface GSMCampaignGroup {
  readonly salesToolId: EquipmentGroupStId
  readonly name: string
  readonly groupKind: GroupKind
  readonly selections: Selections
}

export class ValueAddedServicesAddendum {
  readonly _tag: 'ValueAddedServicesAddendum' = 'ValueAddedServicesAddendum' as const
  constructor(
    readonly groups: Record<EquipmentGroupStId, ValueAddedServicesGroup>,
    readonly interactionSelections: Selections
  ) {}
}

// TODO: Remove as part of GSM Clean up
export class GSMCampaign {
  readonly _tag: 'gsm_campaign' = 'gsm_campaign' as const
  constructor(
    readonly groups: Record<EquipmentGroupStId, GSMCampaignGroup>,
    readonly interactionSelections: Selections
  ) {}
}

export type Offering =
  // This is a temporary fix until the assessment no longer sets ratings.
  // We need this value to represent the case when the assessment is not valid.
  // When the ratings are set on the offering page rather than on the
  // assessment wizard, then we no longer need to create an instance of
  // NoRatings since we can just skip the offering page.
  | NoRatings
  // A quick tender scope has been selected
  | QuickTender
  // The user has chosen a quick tender scope, but the offer can
  // still be converted back to a Flexible
  | QuickTenderWithRatings
  // The user has not yet chosen a quick tender scope
  | QuickTenderScopePending
  // A normal Flexible offering
  | Flexible
  // Value added services
  | ValueAddedServicesAddendum
  | GSMCampaign // TODO: Remove as part of GSM Clean up

export interface CriticalityPath {
  readonly groupId: EquipmentGroupStId
  readonly equipmentCategory: EquipmentCategory
  readonly criticality: Criticality
}
