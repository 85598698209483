import * as React from 'react'
import { t } from 'i18next'
import Dialog from './Dialog'
import { emptyFunction } from '../../../utils'

class OfferVersionSentToSAPDialog extends React.Component<{ onPrimary: () => void }> {
  render() {
    return (
      <Dialog
        title={t('contract.sapIOSuccess.title')}
        showPrimaryButton={true}
        onPrimary={this.props.onPrimary}
        showCancelButton={false}
      >
        {t('contract.sapIOSuccess.desc')}
      </Dialog>
    )
  }
}

export const renderOfferVersionSentToSAPDialog = (onPrimary = emptyFunction) => (
  <OfferVersionSentToSAPDialog onPrimary={onPrimary} />
)
