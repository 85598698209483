import * as t from 'io-ts'
import * as moment from 'moment'
import { getFuturePricesBaseExcluded, getFuturePricesYears, getValidPeriod, isFutureProportionalPrice } from '../offer'
import { Offer, ValidPriceEstimation } from '../../types/types'
import { round, sumTotalDiscountedPrice } from '../prices'
import { FromValidationSpec, ToValidationSpec } from './price-estimation-validation-types'

export function numberAtLeast(minPrice: number): t.Type<number> {
  return t.refinement(t.number, n => round(n) >= round(minPrice), 'Number has to be at least ' + minPrice)
}

const StringToMoment = new t.Type<string, moment.Moment>(
  'DateFromString',
  (m): m is string => typeof m === 'string',
  (m, c) => t.string.validate(m, c).chain(s => (moment(s).isValid() ? t.success(s) : t.failure(s, c))),
  a => moment(a)
)

export const dateSameOrAfter = (minDateS: string): t.Type<string> =>
  t.refinement(
    t.string,
    d => {
      const inputDate = StringToMoment.encode(d)
      const minDate = StringToMoment.encode(minDateS)
      return inputDate.isSameOrAfter(minDate)
    },
    'Date has to be same or after ' + minDateS
  )

export const dateSameOrBefore = (maxDateS: string): t.Type<string> =>
  t.refinement(
    t.string,
    d => {
      const inputDate = StringToMoment.encode(d)
      const maxDate = StringToMoment.encode(maxDateS)
      return inputDate.isSameOrBefore(maxDate)
    },
    'Date can be up to ' + maxDateS
  )

export const nextDay = (date: string): t.Type<string> =>
  t.refinement(
    t.string,
    d => {
      const inputDate = StringToMoment.encode(d)
      const minDate = StringToMoment.encode(date)
      return inputDate.diff(minDate, 'days') === 1
    },
    'Date should be next day to ' + date
  )

export const validDateRange = (minDate: string, maxDate: string) =>
  t.refinement(
    StringToMoment,
    d => dateSameOrAfter(minDate).is(d) && dateSameOrBefore(maxDate).is(d),
    `Date must be in range of ${minDate} and ${maxDate}`
  )

export const fromT = (spec: FromValidationSpec) =>
  t.intersection([nextDay(spec.previousTo), dateSameOrBefore(spec.currentTo)])
export const toT = (spec: ToValidationSpec) => dateSameOrAfter(spec.currentFrom)

function readonlyArrayWithRows<T>(
  numberOfRows: number,
  inputType: t.Type<T>,
  rowsFlag: boolean
): t.Type<ReadonlyArray<T>> {
  return t.refinement(
    t.readonlyArray(inputType),
    r => (rowsFlag ? r.length >= 1 : r.length === numberOfRows),
    `The array has to have atleast 1-25 rows or ${numberOfRows}`
  )
}

// validator used for both FP and PFF in price breakdown & Future price pop up UI
export function futurePriceV(minPrice: number): t.Type<ValidPriceEstimation> {
  return t.refinement(
    t.type({
      from: t.union([t.string, t.null]),
      to: t.union([t.string, t.null]),
      price: t.number
    }),
    r => numberAtLeast(minPrice).is(r.price),
    'price has to be at least ' + minPrice
  )
}

function futureProportionalPriceV(minPrice: number): t.Type<ValidPriceEstimation> {
  return t.refinement(
    t.type({
      from: t.string,
      to: t.string,
      price: t.number
    }),
    r => numberAtLeast(minPrice).is(r.price),
    'price has to be at least ' + minPrice
  )
}

export function getPriceEstimationValidation(
  minPrice: number,
  validPeriodCode: string | undefined,
  isFPP: boolean
): t.Type<ReadonlyArray<ValidPriceEstimation>> {
  return readonlyArrayWithRows(
    getFuturePricesYears(validPeriodCode),
    isFPP ? futureProportionalPriceV(minPrice) : futurePriceV(minPrice),
    isFPP
  )
}

function readonlyArrayWrapper<T>(
  numberOfRows: number,
  inputType: t.Type<T>,
  rowsFlag: boolean,
  expectEmptyArray: boolean
): t.Type<ReadonlyArray<T>> {
  return t.refinement(t.readonlyArray(inputType), r => {
    if (r.length === 0) {
      /*
        empty arrays needs to be right ie., pass validation
          legacy offers with FPP enabled and < 1 year contract duration -> right
        empty arrays needs to be left., fail validation
          offers with only FP enabled and < 1 year contract duration -> left
          offers with no fp, pp enabled -> left
      */
      return expectEmptyArray
    }
    return readonlyArrayWithRows(numberOfRows, inputType, rowsFlag).is(r)
  })
}

export function getPriceEstimationValidationBaseYearExcluded(
  minPrice: number,
  validPeriodCode: string | undefined,
  isFPP: boolean,
  isMultiYearContract: boolean
): t.Type<ReadonlyArray<ValidPriceEstimation>> {
  return readonlyArrayWrapper(
    getFuturePricesBaseExcluded(validPeriodCode),
    futurePriceV(minPrice),
    isFPP,
    isFPP && !isMultiYearContract
  )
}

export function validatePriceEstimation(offer: Offer) {
  const annualSalesPrice = sumTotalDiscountedPrice(offer.prices)
  const validPeriodCode = getValidPeriod(offer)
  const validator = getPriceEstimationValidation(annualSalesPrice, validPeriodCode, isFutureProportionalPrice(offer))
  return validator.decode(offer.priceEstimation)
}
